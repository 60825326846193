export const listDataCeo = [
	{
		id: '01',
		avatar : require('../../assets/images/23.png'),
		name : 'Seung Il Chung | @solomon',
		position : 'Co-Founder | CEO',
		informationOne : 'Graduated from Seoul National University, developed the Internet tax invoice system for the first time in Korea in 2000, and based on this system, it has been established as the standard for electronic tax invoices around the world. In 2006, he moved to Vietnam, studied search engine technology, and created a service called Zing through collaboration with Vina Games. In 2008, he returned to Korea and received the Excellence Award at the SK Telecom Mobile Competition and the Grand Prize at the Venture Award hosted by KT, and his skills are recognized. In 2010, he contributed to the technological development of Korean mobile applications as the founder of the Korean National Software Maestro Course and as a professor of the “SW Maestro Course” composed according to Article 10 (Training of Software Specialists) enacted by the Software Industry Promotion Act. After that, he became interested in technology patents while providing mobile-related technologies through Intellectual ventures Korea. Afterwards, he started research on social enterprises while working as an IT expert commissioner at the small business advancement agency under the Beautiful Foundation. Since 2018, practical research and development of blockchain has been conducted, As a result, BlaaChat, an anonymous messenger linked to the blockchain, Bloodland to connect the VR world with the Earth, and Blood Mainnet, a distributed-oriented blockchain ecosystem, were established. Based on the accumulated technology and experience in the process of developing it, TimeStope is developed and launched. He says all his life experiences are tied to the TimeStope. The TimeStope will be a masterpiece of his life that drew a breakthrough in electronic tax invoice technology, mobile technology, blockchain, and search engines.',
		informationTwo : ''
	},
	{
		id: '02',
		avatar : require('../../assets/images/24.png'),
		name : 'Jin Hoon An | @time2020',
		position : 'Vice President',
		informationOne : `Graduated from Seoul National University. After graduating from Seoul National University, he served as the head of the purchasing department of the E-Land Group and made a significant contribution to the growth of the group, moved to Vietnam with his ideals in education business, And while researching human's mathematical and scientific thinking through educational business in vietnam, he became interested in blockchain, and In 2018, he served as Vice President of Vietnam's Bitbox Exchange. Vietnam, Seoul National University, and Blockchain have a common that they believe in the possibility of TimeStope and joined the team.`,
		informationTwo : ''
	},
	{
		id: '03',
		avatar : require('../../assets/images/25.png'),
		name : 'Kwon Seok Kim | @soultime',
		position : 'Chief Technology Officer',
		informationOne : `Graduated from Seoul National University, and he is a Patent Attorney. He is a close friend of CEO Jeong Seung-il, and he attended junior high school, high school, and university with him, and is currently in charge of technical review of TimeStope. He is also the planning director of the Korean Patent Attorneys Association. He will conduct a role in managing and expanding TimeStope's technology in the future, and currently serves to verify TimeStope's technology.`,
		informationTwo : ''
	},
	{
		id: '04',
		avatar : require('../../assets/images/69.png'),
		name : 'Hwan Oh Bae',
		position : 'Chief Content Officer',
		informationOne : `Based on a number of promotional video making experiences such as Samsung Electronics, POSCO, LG, Hyundai Heavy Industries and SK Telecom, he is creating contents that could make BLOOD's technology stand out more.`,
		informationTwo : ''
	}
];
export const listDevelopers = [
	{
		avatar : require('../../assets/images/26.png'),
		name : 'Aaron Lee',
		address : '@timeman',
		position : 'Senior Software Engineer'
	},
	// {
	// 	avatar : require('../../assets/images/55.png'),
	// 	name : 'Duke Pham',
	// 	address : '@dukepham',
	// 	position : 'Software Developer Manager'
	// },
	{
		avatar : require('../../assets/images/27.png'),
		name : 'Liam Choi',
		address : '@stoper',
		position : 'Software Developer Manager'
	},
	{
		avatar : require('../../assets/images/28.png'),
		name : 'Bernie Pham',
		address : '@bernie999',
		position : 'Software Developer Manager'
	},
	{
		avatar : require('../../assets/images/29.png'),
		name : 'Zack Nguyen',
		address : '@znguyen',
		position : 'Software Engineer'
	},
	{
		avatar : require('../../assets/images/30.png'),
		name : 'Gwyneth Pham',
		address : '@gwyneth999',
		position : 'Software Engineer'
	},
	{
		avatar : require('../../assets/images/31.png'),
		name : 'William Ngo',
		address : '@william084',
		position : 'Software Engineer'
	},
	
	{
		avatar : require('../../assets/images/32.png'),
		name : 'D.K Cao',
		address : '@longdk',
		position : 'Software Engineer'
	},
	{
		avatar : require('../../assets/images/33.png'),
		name : 'Oliver Do',
		address : '@dohung',
		position : 'Software Engineer'
	},
	{
		avatar : require('../../assets/images/34.png'),
		name : 'HieuLuu',
		address : '@hieuluuvt',
		position : 'Software Engineer'
	},
	{
		avatar : require('../../assets/images/35.png'),
		name : 'Edward Vu',
		address : '@hoangvn',
		position : 'Software Engineer'
	},
	{
		avatar : require('../../assets/images/36.png'),
		name : 'Zero Huynh',
		address : '@comeback',
		position : 'Software Engineer'
	},
]

export const listMarketings = [
    {
		avatar : require('../../assets/images/39.png'),
		name : 'Dee Dee Han',
		address : '@maleficent',
		position : 'Marketing Manager'
    },
    {
		avatar : require('../../assets/images/40.png'),
		name : 'Marky Le',
		address : '@philliprins',
		position : 'Marketing Manager'
    },
    // {
	// 	avatar : require('../../assets/images/41.png'),
	// 	name : 'Joey Le',
	// 	address : '@auroraqueen',
	// 	position : 'Marketing Manager'
	// },
	{
		avatar : require('../../assets/images/56.png'),
		name : 'Tiki Won',
		address : '@TiiKii',
		position : 'Marketing Manager'
	},
	{
		avatar : require('../../assets/images/68.png'),
		name : 'Citra Dinh',
		address : '@citra',
		position : 'Marketing Manager'
	},
	{
		avatar : require('../../assets/images/70.png'),
		name : 'Dora Shuang',
		address : '@shuangdora',
		position : 'Marketing Manager'
	},
	{
		avatar : require('../../assets/images/71.png'),
		name : 'Vicky Nguyen',
		address : '@vicky1710',
		position : 'Marketing Manager'
	}	
];

export const listDesigners = [
	{
		avatar : require('../../assets/images/42.png'),
		name : 'Nature Nguyen',
		address : '@duongji90',
		position : 'Design Manager'
    },
    {
		avatar : require('../../assets/images/43.png'),
		name : 'Genmy Nguyen',
		address : '@genmy44',
		position : 'Design Manager'
	}
	// ,
	// {
	// 	avatar : require('../../assets/images/72.png'),
	// 	name : 'Zin Nguyen',
	// 	address : '@tieuho46',
	// 	position : 'Design Manager'
    // }
];

//shop
export const ListItemInShop = [
    {
		id : 1,
        link : '/goods/time-stope-t-shirt', 
        name : 'TimeStope T-shirt',
		image : require('../../assets/images/44.png'),
		listImage : [
            {image : require('../../assets/images/44.png'),name : 'Time Stope T-shirt', class : 'image-cus'},
        ],
        price : '30',
    },
    {
		id : 2,
        link : '/goods/time-stope-polo-shirt', 
        name : 'TimeStope Polo-shirt',
		image : require('../../assets/images/45.png'),
		listImage : [
            {image : require('../../assets/images/45.png'),name : 'Time Stope Polo-shirt', class : 'image-cus'},
        ],
        price : '30',
    },
    {
		id : 3,
        link : '/goods/time-stope-jacket', 
        name : 'TimeStope Jacket',
		image : require('../../assets/images/46.png'),
		listImage : [
            {image : require('../../assets/images/46.png'),name : 'Time Stope Jacket', class : 'image-cus'},
        ],
        price : '50',
    },
    {
		id : 4,
        link : '/goods/time-stope-cap', 
        name : 'TimeStope Cap',
		image : require('../../assets/images/47.png'),
		listImage : [
            {image : require('../../assets/images/47.png'),name : 'Time Stope Cap', class : 'image-cus image-cus1'},
        ],
        price : '20'
	},
	{
		id : 5,
        link : '/goods/time-stope-combo', 
        name : 'TimeStope Set – Cap, T-shirt, Polo-shirt, Jacket',
        listImage : [
            {image : require('../../assets/images/48.png'),name : 'Time Stope Cap', class : 'image-cus image-cus1'},
            {image : require('../../assets/images/44.png'),name : 'Time Stope T-shirt', class : 'image-cus'},
            {image : require('../../assets/images/45.png'),name : 'Time Stope Polo-shirt', class : 'image-cus'},
            {image : require('../../assets/images/46.png'),name : 'Time Stope Jacket', class : 'image-cus'},
        ],
        price : '120',
        state : 'combo'
    }
]

export const ListSize = [
    {  id : 1,  size : 'S', active : true },
    {  id : 2 , size : 'M', active : false },
    {  id : 3,  size : 'L',	active : false },
    {  id : 4,  size : 'XL', active : false },
    {  id : 5,  size : 'XXL', active : false },
    {  id : 6,  size : 'XXXL', active : false },
];


export const listDataCeoWhitePaper = [
	{
		id: '01',
		avatar : require('../../assets/images/23.png'),
		name : 'Seung Il Chung',
		position : 'Co-Founder | CEO',
		informationOne : ' Graduated from Seoul National University, developed the Internet tax invoice system for the first time in Korea in 2000, and based on this system, it has been established as the standard for electronic tax invoices around the world. In 2006, he moved to Vietnam, studied search engine technology, and created a service called Zing through collaboration with Vina Games. In 2008, he returned to Korea and received the Excellence Award at the SK Telecom Mobile Competition and the Grand Prize at the Venture Award hosted by KT, and his skills are recognized. In 2010, he contributed to the technological development of Korean mobile applications as the founder of the Korean National Software Maestro Course and as a professor of the “SW Maestro Course” composed according to Article 10 (Training of Software Specialists) enacted by the Software Industry Promotion Act. After that, he became interested in technology patents while providing mobile-related technologies through Intellectual ventures Korea. Afterwards, he started research on social enterprises while working as an IT expert commissioner at the small business advancement agency under the Beautiful Foundation. Since 2018, practical research and development of blockchain has been conducted, As a result, BlaaChat, an anonymous messenger linked to the blockchain, Bloodland to connect the VR world with the Earth, and Blood Mainnet, a distributed-oriented blockchain ecosystem, were established. Based on the accumulated technology and experience in the process of developing it, TimeStope is developed and launched. He says all his life experiences are tied to the TimeStope. The TimeStope will be a masterpiece of his life that drew a breakthrough in electronic tax invoice technology, mobile technology, blockchain, and search engines.',
	},
	{
		id: '02',
		avatar : require('../../assets/images/24.png'),
		name : 'Jin Hoon An',
		position : 'Vice President',
		informationOne : `Graduated from Seoul National University. After graduating from Seoul National University, he served as the head of the purchasing department of the E-Land Group and made a significant contribution to the growth of the group, moved to Vietnam with his ideals in education business, And while researching human's mathematical and scientific thinking through educational business in vietnam, he became interested in blockchain, and In 2018, he served as Vice President of Vietnam's Bitbox Exchange. Vietnam, Seoul National University, and Blockchain have a common that they believe in the possibility of TimeStope and joined the team.`,
	},
	{
		id: '03',
		avatar : require('../../assets/images/25.png'),
		name : 'Kwon Seok Kim',
		position : 'Chief Technology Officer',
		informationOne : `Graduated from Seoul National University, and he is a Patent Attorney. He is a close friend of CEO Jeong Seung-il, and he attended junior high school, high school, and university with him, and is currently in charge of technical review of TimeStope. He is also the planning director of the Korean Patent Attorneys Association. He will conduct a role in managing and expanding TimeStope's technology in the future, and currently serves to verify TimeStope's technology.`,
	}
];
import React, {useState} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import { Form } from 'reactstrap';


function DeleteAccountComponent(){

	const deleteAccount = () => {
		// console.log("sss")
        alert("Account deletion request completed.")
	};
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent/>
		{/* Render Body */}
		<div className={"deleteAccount"} id="deleteAccount">
            <div className="container-investor">
				<div className="mainTitle">Delete Timestope Account</div>

				<div className="subTitle">
					<span>Confirmation required</span>
					<img alt="TimeStope’s Team" src={require('../../assets/images/1.png')} />
				</div>

				<div className="requestContent">
                    <span className="importantText">
                        You are going to delete <b>Timestope(App)</b> account. Removed account <b>CANNOT</b> be restored! Are you <b>ABSOLUTELY</b> sure?
                    </span>
                    <br/>
                    <span>
                    This action can lead to data loss. To prevent accidental actions we ask you to confirm your
                    intention.<br/>
                    If you request account deletion, all information except your ID will be deleted within 10 days and you will no longer be able to use your account.<br/>
                    Please type account id and password to proceed and click submit button below.
                    </span>
                </div>
                <div className="requestForm">
                    <p>Account</p>
                    <input type="text" className="halfInput"/>
                    <p>Password</p><input type="password" className="halfInput"/>
                    <p>Reason(Option)</p><input type="text" className='reasonInput'/>
                    <input type="submit" className="submitButton" onClick={() => deleteAccount()}/>
                </div>
			</div>
		</div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default DeleteAccountComponent;
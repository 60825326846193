import React, {useState, useEffect} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import axios from 'axios';
import {config} from '../../lib/config'

function OutsideTeam(props){
   
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"04"} />
		{/* Render Body */}
	    <div className={"outside"} id="outside">
            <div className="container-outside">
                
                <div className="title-TimeStope"><span>External Team</span><img alt="TimeStope’s Team" src={require('../../assets/images/1.png')} /></div>
                <div className="btn-center">
					<a target="_blank" href="https://docs.google.com/forms/d/1P9e5lJ989iMqYLFhbmISsJ83iEUayC4Kn0gASkQqjPg/viewform?edit_requested=true" className="link-join-team">
						<div className="btn-join-team">
							Join Our Team
						</div>
					</a>
				</div>

                <div className="container-team-outside">
                    <div style={{color : '#2679f1', fontWeight : 'bold', fontSize : 19, textAlign : 'center'}}>"Become a TimeStope team member. </div>
                    <div style={{color : '#2679f1', fontWeight : 'bold', fontSize : 19, paddingTop : 5,textAlign : 'center'}}>Let's create a new world with us"</div>

                    <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 14, paddingTop : 15, textAlign : 'center'}}>The opportunities for applying to our team are open to anyone who can help us in the development of TimeStope.</div>

                    <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 14, paddingTop : 6, textAlign : 'center'}}>If you have any experience running a community, it would be better, but it doesn't matter if it isn't.</div>
                    <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 14, paddingTop : 6, textAlign : 'center'}}>Give your descendants a free world in which humans can live like humans.</div>

                    <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 14, paddingTop : 5, paddingBottom : 25, textAlign : 'center'}}>Be the master of time, you will become the most important person in this world.</div>

                    <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 19, paddingTop : 30, textAlign : 'center'}}>Join the TimeStope team. Don't hesitate to apply now.</div>
                    <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 19, paddingTop : 6, textAlign : 'center'}}>The TimeStope team is for you. Solomon, CEO of TimeStope Inc.</div>
                </div>

                <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 19, paddingTop : 6, textAlign : 'center', paddingTop : 20, paddingBottom : 10}}>Who joined with us</div>

                <div style={{width : '100%', justifyContent : 'center', alignItems : 'center', display : 'flex', }}>
                    <div style={{backgroundColor : '#031826', width : 150, height  :1}}></div>
                </div>


                <div className="row custom-row" style={{paddingTop : 40}}>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/wshgml1217.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }} alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@wshgml1217</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/vivekjain1k.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp2.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@vivekjain1k</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/timestope6.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@timestope6</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/condor8501.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp3.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@condor8501</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/try1717.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp5.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@try1717</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/jwum1725.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@jwum1725</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/qwstar.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp6.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@qwstar</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/duhan.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp7.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@duhan</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/hwan2ekd.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@hwan2ekd</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/cqw10086.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp3.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@cqw10086</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/time365.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@time365</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/ehsan72.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp9.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@ehsan72</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/prince381.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp10.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@prince381</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/hepi2020.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@hepi2020</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/ars001.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp5.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@ars001</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/namdtvd.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp11.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@namdtvd</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/maximus7.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@maximus7</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/mdruman75.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp2.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@mdruman75</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/atfal.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp6.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@atfal</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/khsjs1139.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@khsjs1139</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/yyww1030.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@yyww1030</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/mkrathore.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp5.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@mkrathore</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/times.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@times</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/chymaker.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp4.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@chymaker</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/dohuy459.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp11.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@dohuy459</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/buz6053.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@buz6053</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/dell1.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp9.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@dell1</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/cniraula.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp10.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@cniraula</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/cyl2415.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@cyl2415</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/fengxiaoyo6.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp3.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@fengxiaoyo6</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/aslam75.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp5.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@aslam75</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/rnjsdlr.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@rnjsdlr</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/yetisce.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp8.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@yetisce</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/togusscjf.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@toguscjf</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/melyont.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp10.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@melyont</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/koshkei.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp9.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@koshkei</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/nami0806.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@nami0806</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/basket.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp12.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@basket</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/emmilord.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp4.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@emmilord</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/assy44.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@assy44</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/tahayasar.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp7.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@tahayasar</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/bbb123.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@bbb123</div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/noPhoto.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@time0</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/noPhoto.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@share</div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 customize-col">
                        <div style={{position:'relative'}}>
                            <img style={{borderRadius: '10%',width: '200px', height: '200px'}} alt={'outside team'} src={require('../../assets/images/noPhoto.jpg')} />
                            <img style={{width:'60px', height:'40px', borderRadius: '10%',position: 'absolute', bottom: '35px', right: 0 }}  alt={'outside team'} src={require('../../assets/images/sp1.jpg')} />
                            <div style={{color : '#06243c', fontWeight : 'bold', fontSize : 16, textAlign : 'center', paddingTop : 10}}>@kkdsapp</div>
                        </div>
                    </div> */}
                </div>

            </div>
        </div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default OutsideTeam;
import React, {useState, useEffect} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';

import _ from 'lodash';

function ResetAccountComponent(props){
	return(
		<div className="App">
		{/* Render Header */}
	    <HeaderComponent activePage={"10"} />
		{/* Render Body */}
	    {/* <div className={"privacy"} id="reset"> */}
        <div className="container-privacy">
            <div className="content-privacy">

            <h1>PRIVACY POLICY</h1>
            <p>
                Last Updated: August 16, 2021
            </p>
            <p>
                Timestope recognizes that people who use Timestope value their privacy. This privacy policy
                ("Policy") describes how Timestope ( "we", "our", or "us") collects, uses, shares, and stores personal
                information of users of this website, https://timestope.com/ (the "Site"). This Policy applies to the
                Site, applications, products and services (collectively, "Services") on or in which it is posted,
                linked, or referenced.
            </p>
            <p>
                By using the Services, you accept the terms of this Policy and our Terms of Use, and consent to our
                collection, use, disclosure, and retention of your information as described in this Policy. If you have
                not done so already, please also review our terms of use. The terms of use contain provisions that limit
                our liability to you and require you to resolve any dispute with us on an individual basis and not as
                part of any class or representative action. IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR
                OUR TERMS OF USE, THEN PLEASE DO NOT USE ANY OF THE SERVICES.
            </p>
            <p>
                Please note that this Policy does not apply to information collected through third-party websites or
                services that you may access through the Services or that you submit to us through email, text message
                or other electronic message or offline.
            </p>
            <p>
                If you are visiting this site from the European Union (EU), see our Notice to EU Data Subjects below for
                our legal bases for processing and transfer of your data.
            </p>
            <h2>WHAT WE COLLECT</h2>


            <p>
                We get information about you in a range of ways.
            </p>
            <p>
                <strong><em>Information you give us</em></strong>
            </p>
            <p>
                Information we collect from you includes:
            </p>
            <ul>
                <li>Network information regarding transactions;</li>
                <li>Contact and identity information, such as your email address;</li>
                <li>Feedback and correspondence, such as information you provide in your responses to surveys, when you
                    participate in market research activities, report a problem with Service, receive customer support
                    or otherwise correspond with us;</li>
                <li>Usage information, such as information about how you use the Service and interact with us; and</li>
                <li>Marketing information, such your preferences for receiving marketing communications and details
                    about how you engage with them.</li>
            </ul>
            <p>
                <strong><em>Know Your Customer Verification Information</em></strong>
            </p>
            <p>
                Separate from the personal data collected in our app and from other sources as set forth herein, and to
                comply with industry regulatory standards and government orders in different aspects such as Anti-Money
                Laundering (AML), Know-Your-Client (KYC), and Counter-Terrorist Financing (CTF), Timestope collects
                personal information such as identity documents (including passport, driver’s license, national identity
                card, state ID card, tax ID number, passport number, driver’s license details, national identity card
                details, visa information, etc.), and may collect proof of address, source of fund declaration, purposes
                of fund documents, and source of wealth (“KYC Data”). This KYC data is collected and used solely to
                verify the identity of the Witness and to be compliant with regulatory requirements and the Timestope
                policy that only real human beings can receive Time. We do not and will not sell data collected for KYC
                purposes without your consent.
            </p>
            <p>
                <strong><em>Information we get from others</em></strong>
            </p>
            <p>
                We may get information about you from other third party sources and we may add this to information we
                get from your use of the Services. Such information may include:
            </p>
            <ul>
                <li>We may collect information that other users provide about you when they use our services. For
                    example, if another user allows us to collect information from their device phonebook or email
                    contacts—and you're one of that user's contacts—we may combine the information we collect from that
                    user's phonebook or email contacts with other information we have collected about you. We may also
                    obtain information from other companies. For example, we offers the option for you to login to our
                    services through your Facebook account. With your authorization through Facebook, Google or iOS,
                    these companies will give us the information that you consent to send us.</li>
                <li>Social Media Widgets: Our Site may include social media features, such as the Facebook logins,
                    "like" button and widgets, such as the "share this" button. These features may collect your personal
                    information and track your use of the Site. These social media features are either hosted by a third
                    party or hosted directly on our Site. Your interactions with these features are governed by the
                    privacy policy of the company providing such functionality.</li>
            </ul>
            <p>
                <strong><em>Information automatically collected when you use our services</em></strong>
            </p>
            <p>
                When you use our services, we collect information about your usage of our apps, activities you have
                through our servers, such as what virtual goods you choose to send, how many you send, the number of
                friends you invite to our apps.
            </p>
            <p>
                Device Information. We collect device-specific information, such as the hardware model, operating system
                version, advertising identifier, unique application identifiers, unique device identifiers, browser
                type, language, wireless network, and mobile network information (including the mobile phone number).
            </p>
            <p>
                Device Phonebook. If you're using our mobile app, we may—with your consent—collect information from your
                device's phonebook.
            </p>
            <p>
                Location Information.When you use our services we may collect information about your location. With your
                consent, we may also collect information about your precise location using methods that include GPS,
                wireless networks, cell towers, Wi-Fi access points, and other sensors, such as gyroscopes,
                accelerometers, and compasses.
            </p>
            <p>
                Log Information. We also collect log information when you use our website. That information includes,
                among other things:
            </p>
            <ul>
                <li>details about how you've used our services</li>
                <li>device information, such as your web browser type, operating system and language</li>
                <li>access times</li>
                <li>pages viewed</li>
                <li>IP address</li>
                <li>identifiers associated with cookies or other technologies that may uniquely identify your device or
                    browser</li>
                <li>pages you visited before or after navigating to our website.</li>
                <li>the pages or features of our Site to which a user browsed and the time spent on those pages or
                    features, the frequency with which the Site is used by a user, search terms, the links on our Site
                    that a user clicked on or used, and other statistics. </li>
            </ul>
            <p>
                We may use cookies or similar technologies to analyze trends, administer the website, track users'
                movements around the website, and to gather demographic information about our user base as a whole.
                Users can control the use of cookies at the individual browser level. For more information, please see
                the section entitled "Cookies Policy" below.
            </p>
            <p>
                We also use Google Analytics to help us offer you an optimized user experience. You can find more
                information about Google Analytics' use of your personal data here:
                https://policies.google.com/privacy?hl=en
            </p>
            <p>
                Information we will never collect. We will never ask you to share your private keys or wallet seed.
                Never trust anyone or any site that asks you to enter your private keys or wallet seed.
            </p>
            <h2>HOW WE USE INFORMATION</h2>


            <p>
                <strong><em>To provide our service</em></strong>
            </p>
            <p>
                We will use your information in the following ways:
            </p>
            <ul>
                <li>develop, operate, improve, deliver, maintain, and protect our services</li>
                <li>communicate with you, to send information, including confirmations, technical notices, updates,
                    security alerts, and support and administrative messages, etc..</li>
                <li>monitor and analyze usage</li>
                <li>personalize the services by, among other things, suggesting friends or message templates, or
                    customizing the content we show you, including ads</li>
                <li>enhance the safety and security of our products and services</li>
                <li>verify your identity, and protect, investigate, and deter against fraudulent, unauthorized, or
                    illegal activity.</li>
                <li>use information we've collected from cookies and other technology to enhance the services and your
                    experience with them</li>
            </ul>
            <p>
                <strong><em>To comply with law</em></strong>
            </p>
            <p>
                We use your personal information as we believe necessary or appropriate to comply with applicable laws,
                lawful requests and legal process, such as to respond to subpoenas or requests from government
                authorities.
            </p>
            <p>
                <strong><em>To optimize our platform</em></strong>
            </p>
            <p>
                In order to optimize your user experience, we may use your information to operate, maintain, and improve
                our Services. We may also use your information to respond to your comments and questions regarding the
                Services, and to provide you and other users with general customer service.
            </p>
            <p>
                <strong><em>To fulfill your requests</em></strong>
            </p>
            <p>
                With your consent, We may use or share your personal information with your consent, such as when you
                instruct us to take a specific action with respect to your personal information, or you opt into third
                party marketing communications.
            </p>
            <h2>SHARING OF PERSONAL INFORMATION</h2>


            <p>
                We do not share or sell the personal information that you provide us with other organizations without
                your express consent, except as described in this Privacy Policy. We disclose personal information to
                third parties under the following circumstances:
            </p>

            <ul>
                <li>Affiliates. We may disclose your personal information to our subsidiaries and corporate affiliates
                    for purposes consistent with this Privacy Policy. </li>
                <li>Business Transfers. We may share personal information when we do a business deal, or negotiate a
                    business deal, involving the sale or transfer of all or a part of our business or assets. These
                    deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.</li>
                <li>Compliance with Laws and Law Enforcement; Protection and Safety. We may share personal information
                    for legal, protection, and safety purposes.</li>
                <li>We may share information to comply with laws.</li>
                <li>We may share information to respond to lawful requests and legal processes.</li>
                <li>We may share information to protect the rights and property of ours, our agents, customers, and
                    others. This includes enforcing our agreements, policies, and terms of use.</li>
                <li>We may share information in an emergency. This includes protecting the safety of our employees and
                    agents, our customers, or any person.</li>
                <li>Professional Advisors and Service Providers. We may share information with those who need it to do
                    work for us. These recipients may include third party companies and individuals to administer and
                    provide the Service on our behalf (such as customer support, hosting, email delivery and database
                    management services), as well as lawyers, bankers, auditors, and insurers.</li>
                <li>With third parties as part of a merger or acquisition. If Timestope gets involved in a merger,
                    asset sale, financing, liquidation or bankruptcy, or acquisition of all or some portion of our
                    business to another company, we may share your information with that company before and after the
                    transaction closes.</li>
                <li>In the aggregate or after de-identification. We may also share with third parties, such as
                    advertisers, aggregated or de-identified information that cannot reasonably be used to identify you.
                </li>
                <li>Other. You may permit us to share your personal information with other companies or entities of your
                    choosing. Those uses will be subject to the privacy policies of the recipient entity or entities.
                </li>
            </ul>

            <p>
                We may also share aggregated and/or anonymized data with others for their own uses.
            </p>
            <h2>USERS OUTSIDE OF THE UNITED STATES</h2>


            <p>
                We welcome users from all over the world, but wherever you live or use our services, we operate our
                services from the United States. This means that we may collect your personal information from, transfer
                it to, and store and process it in the United States and other countries whose local data-protection and
                privacy laws may offer fewer protections than those in your country of residence or from any country
                where you use or access the services.
            </p>
            <p>
                EU users should read the important information provided below about transfer of personal information
                outside of the European Economic Area (EEA).
            </p>
            <h2>HOW INFORMATION IS SECURED</h2>


            <p>
                We retain information we collect as long as it is necessary and relevant to fulfill the purposes
                outlined in this privacy policy. In addition, we retain personal information to comply with applicable
                law where required, prevent fraud, resolve disputes, troubleshoot problems, assist with any
                investigation, enforce our Terms of Use, and other actions permitted by law. To determine the
                appropriate retention period for personal information, we consider the amount, nature, and sensitivity
                of the personal information, the potential risk of harm from unauthorized use or disclosure of your
                personal information, the purposes for which we process your personal information and whether we can
                achieve those purposes through other means, and the applicable legal requirements.
            </p>
            <p>
                In some circumstances we may anonymize your personal information (so that it can no longer be associated
                with you) in which case we may use this information indefinitely without further notice to you.
            </p>
            <p>
                We employ industry standard security measures designed to protect the security of all information
                submitted through the Services. However, the security of information transmitted through the internet
                can never be guaranteed. We are not responsible for any interception or interruption of any
                communications through the internet or for changes to or losses of data. Users of the Services are
                responsible for maintaining the security of any password, biometrics, user ID or other form of
                authentication involved in obtaining access to password protected or secure areas of any of our digital
                services. In order to protect you and your data, we may suspend your use of any of the Services, without
                notice, pending an investigation, if any breach of security is suspected.
            </p>
            <h2>INFORMATION CHOICES AND CHANGES</h2>


            <p>
                <strong><em>Accessing, Updating, Correcting, and Deleting your Information</em></strong>
            </p>
            <p>
                You may access information that you have voluntarily provided through your account on the Services, and
                to review, correct, or delete it by sending a request to support@Timestope.app. You can request to
                change contact choices, opt-out of our sharing with others, and update your personal information and
                preferences.
            </p>
            <p>
                <strong><em>Tracking Technologies Generally</em></strong>
            </p>
            <p>
                Regular cookies may generally be disabled or removed by tools available as part of most commercial
                browsers, and in some instances blocked in the future by selecting certain settings. For more
                information, please see the section entitled "Cookies Policy" below.
            </p>
            <p>
                <strong><em>Google Analytics</em></strong>
            </p>
            <p>
                You may exercise choices regarding the use of cookies from Google Analytics by going to
                https://tools.google.com/dlpage/gaoptout and downloading the Google Analytics Opt-out Browser Add-on.
            </p>
            <p>
                CONTACT INFORMATION. We welcome your comments or questions about this Policy, and you may contact us at:
                <a href="mailto:support@Timestope.app">support@Timestope.app</a> .
            </p>
            <p>
                CHANGES TO THIS PRIVACY POLICY. We may change this privacy policy at any time. We encourage you to
                periodically review this page for the latest information on our privacy practices. If we make any
                changes, we will change the Last Updated date above.
            </p>
            <p>
                Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or upon
                implementation of the changes to the Site (or as otherwise indicated at the time of posting). In all
                cases, your continued use of the the Site or Services after the posting of any modified Privacy Policy
                indicates your acceptance of the terms of the modified Privacy Policy.
            </p>
            <h2>CHILDREN</h2>


            <p>
                Our services are not intended for—and we don't direct them to—anyone under 13. And that's why we do not
                knowingly collect personal information from anyone under 13. Consistent with the requirements of the
                Children's Online Privacy Protection Act (COPPA), if we learn that we have received any information
                directly from a child under age 13 without first receiving his or her parent's verified consent, we will
                use that information only to respond directly to that child (or his or her parent or legal guardian) to
                inform the child that he or she cannot use the Site and subsequently we will delete that information.
            </p>
            <h2>NOTICE TO CALIFORNIA RESIDENTS</h2>


            <p>
                Under California Civil Code Section 1789.3, California users are entitled to the following consumer
                rights notice: California residents may reach the Complaint Assistance Unit of the Division of Consumer
                Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd.,
                Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
            </p>
            <h2>NOTICE TO EU DATA SUBJECTS</h2>


            <p>
                <strong>Personal Information</strong>
            </p>
            <p>
                With respect to EU data subjects, "personal information," as used in this Privacy Policy, is equivalent
                to
            </p>
            <p>
                "personal data" as defined in the European Union General Data Protection Regulation (GDPR).
            </p>
            <p>
                <em>Sensitive Data</em>
            </p>
            <p>
                Some of the information you provide us may constitute sensitive data as defined in the GDPR (also
                referred to as special categories of personal data), including identification of your race or ethnicity
                on government-issued identification documents.
            </p>
            <p>
                <em>Legal Bases for Processing</em>
            </p>
            <p>
                We only use your personal information as permitted by law. We are required to inform you of the legal
                bases of our processing of your personal information, which are described in the table below. If you
                have questions about the legal bases under which we process your personal information, contact us at
                support@Timestope.app.
            </p>
            <table>
                <tr>
                    <th>
                        <strong><em>Processing Purpose</em></strong>
                    </th>
                    <th>
                        <strong><em>Legal Basis</em></strong>
                    </th>
                </tr>
                <tr>
                    <td>
                        <p>To communicate with you</p>
                        <p>To optimize our platform</p>
                        <p>For compliance, fraud prevention, and safety</p>
                        <p>To provide our service</p>
                    </td>

                    <td>
                        <p>These processing activities constitute our legitimate interests. We make sure we consider and
                            balance any potential impacts on you (both positive and negative) and your rights before we
                            process your personal information for our legitimate interests. We do not use your personal
                            information for activities where our interests are overridden by any adverse impact on you
                            (unless we have your consent or are otherwise required or permitted to by law).</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>With your consent</p>
                    </td>
                    <td>
                        <p>Where our use of your personal information is based upon your consent, you have the right to
                            withdraw it anytime in the manner indicated in the Service or by contacting us at <a
                                href="mailto:support@Timestope.app">support@Timestope.app</a>.</p>
                    </td>
                </tr>
            </table>
            <p>
                <em>Use for New Purposes</em>
            </p>
            <p>
                We may use your personal information for reasons not described in this Privacy Policy, where we are
                permitted by law to do so and where the reason is compatible with the purpose for which we collected it.
                If we need to use your personal information for an unrelated purpose, we will notify you and explain the
                applicable legal basis for that use. If we have relied upon your consent for a particular use of your
                personal information, we will seek your consent for any unrelated purpose.
            </p>
            <p>
                <em>Your Rights</em>
            </p>
            <p>
                Under the GDPR, you have certain rights regarding your personal information. You may ask us to take the
                following actions in relation to your personal information that we hold:
            </p>

            <ul>
                <li>Opt-out. Stop sending you direct marketing communications which you have previously consented to
                    receive. We may continue to send you Service-related and other non-marketing communications.</li>
                <li>Access. Provide you with information about our processing of your personal information and give you
                    access to your personal information.</li>
                <li>Correct. Update or correct inaccuracies in your personal information.</li>
                <li>Delete. Delete your personal information.</li>
                <li>Transfer. Transfer a machine-readable copy of your personal information to you or a third party of
                    your choice.</li>
                <li>Restrict. Restrict the processing of your personal information.</li>
                <li>Object. Object to our reliance on our legitimate interests as the basis of our processing of your
                    personal information that impacts your rights.</li>
            </ul>

            <p>
                You can submit these requests by email to support@Timestope.app. We may request specific information
                from you to help us confirm your identity and process your request. Applicable law may require or permit
                us to decline your request. If we decline your request, we will tell you why, subject to legal
                restrictions. If you would like to submit a complaint about our use of your personal information or
                response to your requests regarding your personal information, you may contact us at
                support@Timestope.app or submit a complaint to the data protection regulator in your jurisdiction. You
                can find your data protection regulator here.
            </p>
            <p>
                <em>Cross-Border Data Transfer</em>
            </p>
            <p>
                Please be aware that your personal data will be transferred to, processed, and stored in the United
                States. Data protection laws in the U.S. may be different from those in your country of residence. You
                consent to the transfer of your information, including personal information, to the U.S. as set forth in
                this Privacy Policy by visiting our site or using our service.
            </p>
            <p>
                Whenever we transfer your personal information out of the EEA to the U.S. or countries not deemed by the
                European Commission to provide an adequate level of personal information protection, the transfer will
                be based on a data transfer mechanism recognized by the European Commission as providing adequate
                protection for personal information.
            </p>
            <p>
                Please contact us if you want further information on the specific mechanism used by us when transferring
                your personal information out of the EEA.
            </p>
            <h2>COOKIES POLICY</h2>


            <p>
                We understand that your privacy is important to you and are committed to being transparent about the
                technologies we use. In the spirit of transparency, this policy provides detailed information about how
                and when we use cookies on our Site.
            </p>
            <p>
                <em>Do we use Cookies?</em>
            </p>
            <p>
                Yes. We and our marketing partners, affiliates, and analytics or service providers use cookies, web
                beacons, or pixels and other technologies to ensure everyone who uses the Site has the best possible
                experience.
            </p>
            <p>
                <em>What is a Cookie?</em>
            </p>
            <p>
                A cookie ("Cookie") is a small text file that is placed on your hard drive by a web page server. Cookies
                contain information that can later be read by a web server in the domain that issued the cookie to you.
                Some of the cookies will only be used if you use certain features or select certain preferences, and
                some cookies will always be used. You can find out more about each cookie by viewing our current cookie
                list below. We update this list periodically, so there may be additional cookies that are not yet
                listed. Web beacons, tags and scripts may be used in the Site or in emails to help us to deliver
                cookies, count visits, understand usage and campaign effectiveness and determine whether an email has
                been opened and acted upon. We may receive reports based on the use of these technologies by our
                service/analytics providers on an individual and aggregated basis.
            </p>
            <p>
                <em>Why do we use Cookies?</em>
            </p>
            <p>
                We generally use Cookies for the following purposes:
            </p>
            <ul>

                <li>To recognize new or past customers.</li>
                <li>To store your password if you are registered on our Site.</li>
                <li>To improve our Site and to better understand your visits on our platforms and Site.</li>
                <li>To integrate with third party social media websites.</li>
                <li>To serve you with interest-based or targeted advertising.</li>
                <li>To observe your behaviors and browsing activities over time across multiple websites or other
                    platforms.</li>
                <li>To better understand the interests of our customers and our website visitors.</li>
            </ul>

            <p>
                Some Cookies are necessary for certain uses of the Site, and without such Cookies, we would not be able
                to provide many services that you need to properly use the Site. These Cookies, for example, allow us to
                operate our Site so you may access it as you have requested and let us recognize that you have created
                an account and have logged into that account to access Site content. They also include Cookies that
                enable us to remember your previous actions within the same browsing session and secure our Sites.
            </p>
            <p>
                We also use functional Cookies and Cookies from third parties for analysis and marketing purposes.
                Functional Cookies enable certain parts of the site to work properly and your user preferences to remain
                known. Analysis Cookies, among other things, collect information on how visitors use our Site, the
                content and products that users view most frequently, and the effectiveness of our third party
                advertising. Advertising Cookies assist in delivering ads to relevant audiences and having our ads
                appear at the top of search results. Cookies are either "session" Cookies which are deleted when you end
                your browser session, or "persistent," which remain until their deletion by you (discussed below) or the
                party who served the cookie. Full details on all of the Cookies used on the Site are available at our
                Cookie Disclosure table below.
            </p>
            <p>
                <em>How to disable Cookies. </em>
            </p>
            <p>
                You can generally activate or later deactivate the use of cookies through a functionality built into
                your web browser. To learn more about how to control cookie settings through your browser:
            </p>
            <p>
                Click <a href="https://support.google.com/chrome/answer/95647?hl=en">here</a> to learn more about how to
                clear, enable and manage cookies in Chrome;
            </p>
            <p>
                If you want to learn more about cookies, or how to control, disable or delete them, please visit
                http://www.aboutcookies.org for detailed guidance. In addition, certain third party advertising
                networks, including Google, permit users to opt out of or customize preferences associated with your
                internet browsing. To learn more about this feature from Google, click <a
                    href="https://adssettings.google.com/u/0/authenticated?hl=en">here</a>.
            </p>
            <p>
                To control flash cookies, which we may use on our Site from time to time, you can go to this <a
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">link</a>
                because Flash cookies cannot be controlled through your browser settings. Please note that if you
                decline the use of Cookies, some functions of the website may be unavailable and we will not be able to
                present personally tailored content and advertisements to you.
            </p>
            <p>
                We may link the information collected by Cookies with other information we collect from you pursuant to
                this Privacy Policy and use the combined information as set forth herein. Similarly, the third parties
                who serve cookies on our Site may link your name or email address to other information they collect,
                which may include past purchases made offline or online, or your online usage information. If you are
                located in the European Economic Area, you have certain rights that are described above under the header
                "Notice to EU Data Subjects", including the right to inspect and correct or delete the data that we have
                about you.
            </p>
            <p>
                <em>Cookies Disclosure</em>
            </p>
            <table>
                <tr>
                    <td><strong>Name of Cookie/Identifier</strong>
                    </td>
                    <td><strong>What does the cookie generally do (e.g., website function and administration, analytics,
                            marketing)?</strong>
                    </td>
                    <td><strong>Is it a 1<sup>st</sup> or 3<sup>rd</sup> party cookie and what is the name of the party
                            providing it?</strong>
                    </td>
                    <td><strong>What type of cookie is it (persistent or session)?</strong>
                    </td>
                    <td><strong>What is the duration of the cookie on the website (if not cleared by the user)?</strong>
                    </td>
                </tr>
                <tr>
                    <td>Google Analytics
                    </td>
                    <td> Analytics
                    </td>
                    <td>3rd - Google
                    </td>
                    <td> Persistent
                    </td>
                    <td> 2 years
                    </td>
                </tr>
            </table>
            </div>
        </div>
        {/* </div> */}
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default ResetAccountComponent;
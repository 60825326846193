import React, { useState, useEffect } from 'react';
import HeaderComponent from './headerComponent';
import FooterComponent from './footerComponent';
import { Link } from 'react-router-dom';
import { config } from '../../lib/config';
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

function MainComponent(props) {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [content, setContent] = useState('');

	const [activeNotice, setActiveNotice] = useState(1);

	const [preventClick, setPreventClick] = useState(false);
	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}
	const [inviteCode, setInviteCode] = useState('');
	const [shoInvitationCode, setShowInvitationCode] = useState(false);
	const history = useHistory();

	const checkUser = async (userId) => {
		// const checkuser = await axios.post(config.server_timeStope + '/v1/time/user/check-userid', { "userId": userId });
		// console.log('checkuser ====', checkuser);
		// if(checkuser.data && checkuser.data.success){
		setShowInvitationCode(true);
		setInviteCode(userId);
		localStorage.setItem('useIdCode', userId)
		// }else{
		// 	return history.push(`/`);
		// }
	}
	useEffect(() => {
		if (props.match.path == "/support") document.getElementById('section-contact-us').scrollIntoView();
		if (props && props.match && props.match.params && props.match.params.userId) {
			checkUser(props.match.params.userId)
		}
	});
	const _onHanldeSubmit = async () => {

		try {
			if (_.trim(firstName) === '') {
				return alert('Please enter your first name.');

			}
			if (_.trim(lastName) === '') {
				return alert('Please enter your last name.');

			}
			if (!validateEmail(email)) {
				return alert('Invalid your email.');
			}
			const data = {
				name: firstName + " " + lastName,
				email: email,
				comments: content
			};
			setPreventClick(true);
			const dataRespon = await axios.post(`${config.server_timeStope}/v1/shop/contact`, data);

			if (dataRespon.data && dataRespon.data.success) {
				setFirstName('');
				setLastName('');
				setEmail('');
				setContent('');
				setPreventClick(false);
				alert('SUCCESS!')
			}

		}
		catch (e) {
			setPreventClick(false);
			alert('This feature is currently unavailable. Please contact us at the email address below. timestope.help@gmail.com')
		}

	}
	return (
		<div className="App">
			{/* Render Header */}
			<HeaderComponent activePage={"01"} />
			{/* Render Body */}

			<div className={"main"} id="home">
				<div className="header-main">
					<div className="row customize-row">
						<div className="col-lg-6 col-md-6 col-12 custom-col-left">
							<div className="padding-container">
								<div className="time-precious">YOUR TIME IS PRECIOUS.</div>
								<div className="time-money">HUMAN TIME IS FAIR TO ALL.</div>
								<div className="time-money time-money_cus">24 HOURS A DAY!</div>

								<div className="text-header-left_cus">Now the world of a new currency system begins.</div>
								<div className="text-header-left_cus">Your time is not free.</div>
								<div className="text-header-left_cus">Now save your time. </div>
								<div className="text-header-left_cus">And use that  time like money.</div>
								<div className="text-header-left_cus">It's simple.</div>
								<div className="text-header-left_cus">Just download and launch the TimeStope app.</div>
								<div className="text-header-left">Now you are the master of the world.</div>

								<div className="container-btn">
									<Link className="btn-join-our-team" to="/internal-team">
										Join Our Team
									</Link>
									<Link className="btn-download" to="/download">
										Download App
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-12 custom-col-right">
							<iframe width="560" height="315" src="https://www.youtube.com/embed/ExgAn0Kj9YE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				</div>
				<div className="section-notice-timestope">
					<div className="list-notice">

						<div className={'section-post'}>
							<div className={"header-post"} onClick={() => setActiveNotice(1)}>
								<div className="number">#</div>
								<div className="title">The Fee Distribution, L2 Distribution, Swap Ratio, and Smart Deposit</div>
								<div className="create-post">16-08-2022</div>
							</div>
							{activeNotice == 1 && <div className={"content-post"}>
								Hello to our TimeStope Family, <br /><br />

								This is Solomon, the representative of Timestope.<br /><br />

								As scheduled, on August 15, 2022, we will announce the distribution of the fee, airdrop, and L2 distribution. The mainnet of the Fee Chain will start around October 10, 2022.<br /><br />

								Fee chain is a project that started with the Blood Project in 2017, goes through Blaa Chat and eventually Timestope. This has been over 5 years, <br />
								and I would like to say  it is as a  result of accumulated experience over this time.<br /><br />

								The Korean pronunciation of Blood is Fee, and Fee also means fee as in transaction payment. However Fee as blood means the most important component that flows through the human body.<br /><br />

								The mainnet of the Fee Chain will start on October 10, 2022, and the detailed plans on how the Fee, Time, and L2 will be distributed to 300,000 KYC users will be<br />
								highlighted according to the situation after the announcement of the mainnet. There will be clarity to the distribution process and detailed steps. <br /><br />

								We plan to complete the distribution work by at least December 31, 2022. Prior to the announcement of the specific plan, in a broad sense, the initial issuance will be 40 billion Fee.<br /><br />

								Fee.Chain is a staking mainnet system consisting of Ethereum wallet address base and Fee addresses. This means that it can be used as an alternative <br />
								to Ethereum aimed at compatibility with Ethereum. <br /><br />

								The current value of Ethereum is about $2,000, so even if the conversion to Ethereum Beacon Chain staking succeeds in September 2022, the transfer fee will be 0.4 USD per <br />
								transaction even if the minimum value is reflected. It uses gas to transfer Ethereum.  <br />
								The smallest unit of gas is gWei. It uses about 21,000 Gas per transaction.  The minimum unit for using gas is gWei, and if the value of that unit rises further in the future, <br />
								the transaction transmission cost will continue to rise.<br /><br />

								Since Fee is compatible with Ethereum in many ways, it can be operated as an alternative target for Ethereum. If Ethereum is oriented toward the mechanical goal of Ether, <br />
								Fee Chain is oriented toward the human goal of blood. If everything is processed with blockchain at some point in the future, in fact, 1 million transactions per second is <br />
								not a big processing power.  In other words, one single blockchain network is always insufficient and must be combined with a new network.  <br />
								This can be compared to using multiple carriers when connecting to the Internet.<br /><br />

								The essence of using various Internet networks lies in the data they send and receive. In the end, like data on the Internet, a blockchain network will be evaluated based on <br /><br />

								1. Network size <br />
								2. Transmission Speed <br />
								3. Transmission Cost <br />
								4. Network Stability.<br /><br />

								Now you need to be prepared to accept it as a reality, not a blockchain gambling or scam. At least, given that the size of the network will be sufficiently recognized <br />
								as a scale of at least 300,000 KYC users, and the transmission speed may guarantee at least 10k TPS,  the speed is based on a minimum of 24 nodes, <br />
								and the speed increases as the number of nodes increases. The transfer cost is determined from a maximum of 0.0021 Fee to a minimum of 0.00000000000021Fee. <br />
								This means that even if the value of the fee rises a lot in the future, the value of the transmission cost can be very low.<br /><br />

								Since Fee.Chain satisfies the criteria of No. 1, 2, and 3 sufficiently, Fee Chain's focus will be on network stability, and to prove its stability, <br />
								the network will be maintained for a long time before it will be evaluated. <br /><br />

								In the late 1990s, when the Internet was first commercialized, it cost 0.1 dollars to send and receive 1 kilobyte of data. Today,  <br />
								we spend little or nothing  to exchange 1kb of data.How much will it cost to exchange 1kb of data in the era of mobile in the 2020s?  <br />
								If we live in a gigabit internet world that downloads 1 gigabyte of data in 1 minute, if we had to use 1 cent for 1,000 bytes of data, the current mobile era would not have <br />come.<br /><br />


								The same goes for blockchain.  What must be compared with the transmission speed is to solve the transmission cost, as the Fee Chain exists, <br />
								Fast transmission speed and low transmission cost will be the most realistic reasons for blockchain to survive in the next 10 years.<br /><br />

								The answer as to why we invest in Fees and Time is not emotional or pretentious.  It is very rational and reasonable. Now the time for the fee is approaching. <br />
								I am immersed in the problem of transmission speed, perceive the blockchain mainnet coin only as a means of investment, and look at the perceptions of exclusive blockchain <br />networks.
								I would like to say that blockchain is still in the early days of awareness.  At some point in the future, blockchain will either be in real life or disappear altogether. <br />
								If used in real life, the transmission cost of 1 transaction is the most important factor in the blockchain.  We do not transfer a dollar through the bank,<br />
								It will be sent using the blockchain network.  After all, if the value pegged to a dollar is certain, it will not matter which network it is, and if so,<br />
								If it operates stably for a long time and the transmission cost becomes low, 1 dollar will be transmitted through the network.  This is what Fee Chain has in essence, <br />
								and it is also the goal of Smarty Deposit expressed in Layer 3.  For pegging all currencies, making it possible to achieve the goal of pegging with all blockchain networks.<br />
								It is designed and will evolve and develop with the goal of low cost of 1 transaction transmission. The hand that points to the moon cannot cope with it.  <br />
								NFTs have to have a value in themselves, and that value is not proportional to the cost of transmission. We believe that as the cost of creating an NFT converges to zero, <br />
								the idea will shift in the direction of increasing the value of the blockchain.<br /><br />


								The 40 billion fee asset is stored in one specific wallet, and it is divided into two wallets.<br />
								20 billion will be moved to the company's asset wallet, and then all assets in the wallet will be stored and distributed as possible. <br />
								All of the company's asset wallets are disclosed on web pages and social media so that all users can know.  All assets that are distributed and stored will be staked.  <br />
								All additional issued assets obtained through staking can be used for fee and time swap, or can be used if necessary for company operation.<br /><br />

								Fee and time are completely interchangeable in both directions.  In other words, Fee and CTime are the same as one asset, and 3 CTime is maintained at the rate of 1 Fee.<br />
								This will enable listing on a single basis when Fee and Time are listed on the exchange in the future.<br />
								Based on L2, one L2 offers daily interest of  3 CTimes every day.  Fee.Chain is a human-like blockchain consisting of three layers as a system of heaven and earth. <br />
								When swapping Fee and Time, a 3% fee is generated to prevent reckless swapping. <br />
								This is because the policy is to fix the value of 1 Fee to 3 Time and keep it stable as a ratio that allows free exchange of Fee and Time. <br />
								This is the most complete exchange rate as it was determined in consideration of the current number of users, the number of users to increase in the future, and the total amount of L2 KYC.<br /><br />

								If you do this, you can only list Fees on the exchange, saving money and time, and it has the effect of making management and marketing easier.<br /><br />


								A total of 40 billion initial fee are issued, of which 20 billion are managed by the Timestope team.  The entire amount of 20 billion pieces will be staked, <br />
								and the interest accrued from staking is used for swap of time and fee.  Of the remaining 20 billion assets, it is estimated that approximately 11 to 12 billion assets are promised to be distributed.<br />
								Excluding the amount for which distribution has already been decided, there will definitely be 8 billion Fees left.<br /><br />

								1. 4 billion Fee will be distributed to the founder, representative Solomon.<br />
								2. 1 billion Fees are distributed to developers and community management team leaders, but not all at once, but periodically according to their contribution.<br />
								3. The 2 billion fee is temporarily allocated as the share of the alliance partner in case of various alliances such as listing in the future, all the relevant amount is staked, <br />
								and the new amount generated by staking is used for time and fee swap.<br />
								4. The remaining 1~2 billion left can be distributed in various ways such as airdrops or ICOs.<br /><br />

								The specific distribution plan for 4 items of TimeStope App users is as follows.<br />
								a.  10 Fees will be distributed to all users of the TimeStope app who have completed KYC registration.<br />
								b.  Among users of the TimeStope app, 10 Fees are distributed to each KYC-witnessed user.  In other words, if there are 100 KYC users in your earning team, 1,000 Fee will be distributed.<br />
								c.  Among users of the TimeStope app, among users who have completed KYC registration, if they watch an advertisement, 1 Fee is distributed for every 100 advertisements.  <br />
								You accumulate and earn points, including ads you have already watched.<br />
								d.  Among users of TimeStope app who have completed KYC registration, 1 Fee is distributed per 100 diamonds based on offerwall advertisement.<br />
								e.  Among users of TimeStope app, who have completed KYC registration, 1 Fee is distributed for every 50 attendance points.<br /><br />


								City Walk<br />
								The Android and iPhone versions of the City Walk app are scheduled to be launched in September, and post-development work such as game balance adjustment and
								subscription payment is currently in progress.<br />
								First, there are 4 shoes for Subscribe, which will be applied to CityWalk. Shoes make the game easier when you run the game, and more steps are rewarded during the subscription period.  <br />
								The details will be specified after the game is launched.<br />
								In addition to in-game rewards, when you subscribe, there are Green, Black, Silver, and Gold Shoes depending on the subscription item consisting of $10, $20, $30, and $50.<br /><br />

								The specific distribution plan for City Walk app users of 4 items is as follows.  <br /><br />

								a.  Green Shoes can be rewarded for 1000 Fees and 0.5 L2.<br />
								b.  Black Shoes can be rewarded with 2200 Fees and 1 L2.<br />
								c.  Silver Shoes can be rewarded for 3500 Fee and 1.5 L2.<br />
								d.  Gold Shoes can be rewarded with a reward of 6000 Fees and 2.5 L2.<br /><br />

								The subscription is paid monthly, and the subscription event ends when all L2 quantities are exhausted.  The surplus quantity of L2 is about 100,000 pieces.<br /><br />

								Considering the iPhone and Android payment cancellation conditions, the fee will be compensated 2 weeks after the subscription is executed.  However, <br />
								if it is confirmed that the cancellation has been made later,<br />
								The user will be judged to be a fraudulent account and the account will be suspended.<br /><br />

								[Distribution to L2 Assets]<br /><br />

								L2 is a project that has matured and developed for a very long time.  Solomon, the representative of TimeStope Team, designed the Korean software maestro course in 2010. <br />
								During the first maestro course, we conducted a project called LBSNic.  As a result of this project, the first software maestro (Lee Jeek-geun mentee) was born.<br />
								He was specially appointed to Samsung Electronics on very good terms for this course.  100 genius developers in Korea were carefully selected through the famous software maestro Course. <br />
								It will interest you to also be aware that  Lee Jun-Seok, former leader of the 1st ruling party of the Republic of Korea, also came from this process.  <br />
								Ten projects were selected through rigorous screening, and one project selected in the inter-project competition is LBSNIc.<br /><br />

								This project started with a plan to partition the district and make it DNS, and at the time, it was in mind to combine it with blockchain.<br />
								As time passed, the project was reborn as Bloodland in 2017. Timeland has evolved again, and now we are about to put an end to the final Layer 2. <br />
								Layer 2 will be connected with Conware's life game, adding game characteristics, and it will have its own vitality.  However, it is judged that the release of the game will take more than one year. <br />
								Before the start of the game, each L2 will issue 3 new cTimes every day.  The distribution of these assets will begin on October 10, 2022.   <br />
								Various other game characteristics will be combined with Conware's life game,<br />
								Conware's black and white game of life is changed to 8 colorful colors and applied to the game.<br /><br />

								1. Of the L2 assets, 100,000 will be distributed to the representatives of the Timestop Team. <br />
								2. 50,000 of L2 assets are divided and distributed according to the contribution of developers and managers (internal team) of the TimeStope team. <br />
								3. Among L2 assets, general land is distributed at 1 rand per $20 subscription value. <br />
								4. Among L2 assets, general land is distributed at 1 land per 1 million steps. <br />
								5. Smarty Deposit wraps heterogeneous coins in the Layer of Fee Chain, which is called L3.  If L3 meets certain maintenance conditions, L2 can be compensated. <br />
								6. All L2 assets whose owners are not determined can be arbitrarily disposed of by the company and can be exchanged for stable coins, etc.<br /><br />

								* All L2 assets are distributed on a first-come, first-served basis until asset distribution is no longer possible.<br /><br />

								After October 10, 2022, all Timeland assets will be converted to L2.
								As of now, it is estimated that 400,000 assets will be converted from Timeland to L2.  One million L2 is a litigation asset. <br />
								The tokenization of L2 is October 10, 2023, before that, L2 holders can upgrade L2 using Time.<br /><br />

								Of the 1 million scarce assets, 100,000 will have the properties of NFT DNS and will be used as Web3 general domains.  5,000cTime is required for a user to upgrade L2 with this attribute. <br />
								Of the 1 million scarce assets, 10,000 will have NFT DNS properties and will be used as Web3 4-digit domains.  10,000cTime is required for a user to upgrade L2 with this attribute. <br />
								Of the 1 million scarce assets, 100,000 will have the property of 16x16 pixel NFT, and will be used as special characters.  5,000cTime is required for a user to upgrade L2 with this attribute.<br /><br />

								[cTime - Use of Time Token]<br /><br />

								Time Token exists in 4 phases on 4 shards.  T0, T1, T2, and T3 all have the same value, and have a value of 1/3 Fee.  When used as T0, the fee is increased, and when used as T1, T2, T3 <br />
								It induces decentralized processing of Time Token by lowering the fee. <br />
								Time Token is for short-term (planned within one year) use <br />
								a.  L2 upgrade <br />
								b.  In-game use, CityWalk and Texas Holdem launch within the year<br /><br />

								Medium-term use of Time Token (planned within the next two years) <br />
								c.  Time trading platform, SellMeTime platform, a system that trades 3T of Time, Talent, and Things, and uses Time as a payment method.  <br />
								If the Korean carrot market is a short-distance P2P goods relay platform focused on second-hand transactions,<br />
								SellMeTime is a short-distance work relay platform - a time relay platform, a long-distance talent relay platform, and a platform that relays goods. <br />
								It is a platform combined with Action KYC. It is a platform with a maturity of about 70% developed for 6 months. <br />
								This is a new discourse after Fee Chain for the Time Stop team to prepare for a new voyage in the future. <br />
								d.  Arena.Direct Texas Holdem is a beta test version of Arena.Direct, which is a platform where you can bet on any game. <br />
								e.  Used as interest on Smarty Deposit.  The Sky, Land, Human system is based on the sky (sky), which leads compensation such as advertisements and other rewards, <br />
								and the land (land) and KYC, which lead the production of land in L2.<br /><br />

								It is a three-way system of human (human-human) that capitalizes the 24 hours of human time.  All new registrations of KYC users will be blocked as of October 10, <br />
								and the total amount of L2 will be limited to 1 million.  This means that among the systems of heaven, earth and humans, compensation from land and humans is a limited resource, <br />
								and cTime, which consists of the sky compensation system, becomes the most core value of Fee.Chain. <br />
								Time token is converted from human time to asset time, and Smarty Deposit stores Bitcoin in L3 (layer 3 network), and rewards it with Time.  In other words, it is a core technology <br />
								and concept that allows staking of all assets that cannot be staking, and Bitcoin is typically staking, and through L3, amazing transfer speed and less than 1 cent transfer cost, <br />
								all coins in the world It will be stored in L3.<br /><br />

								The above was the distribution plan of Fee and L2.<br />
								thank you

							</div>}
						</div>

						<div className={'section-post'}>
							<div className={"header-post"} onClick={() => setActiveNotice(2)}>
								<div className="number">#</div>
								<div className="title">Announcement of Tau day Betanet and Smarty Deposit</div>
								<div className="create-post">28-06-2022</div>
							</div>
							{activeNotice == 2 && <div className={"content-post"}>
								Hello Everyone,<br /><br />

								This is Solomon, the representative of the TIMESTOPE team.<br /><br />

								Our TimeStope symbol is a monetary symbol for Tau, which can best express the meaning of time.<br /><br />

								Time comes twice in 12 hours.  It symbolizes that a round clock rotates twice. Mathematically, the circumference is 1pi, and 2pi with it is called Tau<br /><br />


								On this TAU DAY, I would like to make a meaningful announcement.<br /><br />

								Starting with Blood, a blockchain network at the end of 2017, there were many shortcomings in the short period of 5 years, but nevertheless, we have reached this moment by trying to find the right direction.<br /><br />

								A lot has changed, felt a lot, and developed a lot in the past 5 years.<br /><br />

								First of all, my five-year practice on blockchain technology has taught me and my team a lot.<br /><br />

								Humans can't be perfect, so we have the idea that we should be humble.<br /><br />

								In order to survive in a rapidly changing technology and market, we must make another evolutionary change.<br /><br />

								We have discovered a new zeitgeist with a discovery attitude.<br /><br />

								Blockchain technology has already become an important economic indicator that can no longer be discarded.<br /><br />

								Even though the winter of crypto assets is coming, we believe that after this winter, a brilliant spring awaits us.<br /><br />


								Heuristics is that I make jewels, I make treasures.<br /><br />

								Seeing the world with an attitude of discovering the world.  Whenever we find something better, we will innovate with an attitude of improving for the better.<br /><br />

								Below is a summary of Timestorp's new Refactory.<br /><br />

								Among the previously planned things, things that did not fit the spirit of the times should be boldly removed and we moved forward.<br /><br />

								We're going down a road we've never been before, and we're going to innovate whenever necessary.<br /><br />


								This time, the goal we are trying to achieve is simpler and the ecosystem has been re-established in a way that is easier to understand.<br /><br />

								Thank you<br /><br />
								<span>Download: </span>
								<a href='/TimestopeRefactory_EN.pdf' download>TimestopeRefactory(EN).pdf</a>

							</div>}
						</div>

						<div className={'section-post'}>
							<div className={"header-post"} onClick={() => setActiveNotice(3)}>
								<div className="number">#</div>
								<div className="title">Unauthorized use of the TimeStope network</div>
								<div className="create-post">04-05-2022</div>
							</div>
							{activeNotice == 3 && <div className={"content-post"}>
								Hello. This is the TimeStope team.<br /><br />

								A case of unauthorized use of the TimeStope network has been discovered, so we ask all users to be more careful.<br /><br />

								We found that there was a case in the Tron network where TIME tokens, trademarks, logos, and even the website address had been stolen.<br />
								The TimeStope team has protested, but the decentralized network Tron responded that they don't have the authority to delete or sanction tokens.<br />
								The examples of theft on the Tron network are shown as follows, and these tokens are not affiliated with our TimeStope team.<br /><br />

								TUNnXZE6sXACqx7Y48gaTFgiUZ4VCk9L4n<br />
								TMAhq92QzoipeAj8tvsdqm6QMDpR9L8FDd<br />
								TBRoDRvKiFRYJVd2LLGJzf6vcjt95GLoU8<br />
								TSeXVwZQc5V4M51mfW1jT4bAfcwVZQsTwE<br />
								TY3hvHs1gS9d933nYc4VtMSdAy13etCbyx<br /><br />

								In addition, TIME tokens are also being stolen and used by other networks.<br />
								We would like to inform you that all tokens used on any other network are not affiliated with the TimeStope Team, and users are requested to pay special attention to avoid incidents due to stolen tokens.<br />
								We are continuously monitoring cases of TIME tokens being stolen and plan to add cases of theft through a notice on our website.<br /><br />

								The TimeStope team is currently devoting itself to the mainnet development. We plan to announce the mainnet in the near future and will issue the TIME tokens through Fee Chain.<br /><br />

								Once again, we demand users to be cautious, and tokens that have not been announced on our official notice have nothing to do with the TimeStope team.<br /><br />

								Thank you.
							</div>}
						</div>

						<div className={'section-post'}>
							<div className={"header-post"} onClick={() => setActiveNotice(4)}>
								<div className="number">#</div>
								<div className="title">Notice about advertisements</div>
								<div className="create-post">29-03-2021</div>
							</div>
							{activeNotice == 4 && <div className={"content-post"}>
								1. Rewarded ads provided by TimeStope are only available in the following 24 areas:
								Arab Emirates, Bahrian, China, Germany, Egypt, Spain, France, United Kingdom, Indonesia, India, Italy, Japan, South Korea, Kuwait, Lebanon, Malaysia, Qatar, Russia, Saudi Arabia, Singapore, Thailand, Turkey, Taiwan, and Vietnam.<br /> <br />

								2. Restrictions maybe applied when viewing ads on emulators or viewing multiple times on the same IP. Theoretically, the server is restricting one user to have only one IP to view ads, and in case of using the the same network , please notice that there may be some limitations in viewing ads.<br /> <br />

								3. If it is found that watching advertisements at a very regular interval or at a certain pattern, watching advertisements of that ID can be considered as watching advertisements through some Macros Program.<br /> <br />

								4. For accounts that induce invalid clicks with large access traffic, future advertisements can be blocked or rewards obtained through advertisements can be canceled.<br /> <br />

								5. It may take a certain period of time to provide rewarded advertisements to other areas excluding the above 24 areas. Moreover, we will notify you when advertisements are provided.<br /> <br />
							</div>}
						</div>

						<div className={'section-post'}>
							<div className={"header-post"} onClick={() => setActiveNotice(5)}>
								<div className="number">#</div>
								<div className="title">NOTICE OF ACCOUNT RESTRICTION</div>
								<div className="create-post">01-02-2021</div>
							</div>
							{activeNotice == 5 && <div className={"content-post"}>
								1. If there are two or more IDs registered in one device, all IDs will be deleted without notice and all assets will be confiscated.<br /> <br />

								2. If you create more than two IDs with your own information, all IDs will be deleted without notice and all assets will be confiscated.<br /> <br />

								3. When it is detected as a forged or altered ID even if the ID has already been verified through KYC.Direct, the ID will be deleted without notice and all assets will be confiscated.<br /> <br />

								4. If you create more than two IDs with one Google Account (or Apple account), all IDs will be deleted without notice and all assets will be confiscated.<br /> <br />

								5. You can create up to 10 IDs with the same IP.<br /> <br />
							</div>}
						</div>
					</div>
				</div>
				<div className="section-info-timestope">
					<div className="text-1 cus-color-and-font">
						You can collect TIME easily on your phone
					</div>
					<div className="text-2 cus-color-and-font">
						and our website.
					</div>
					<div className="text-3 cus-color">
						Time is so precious.
					</div>
					<div className="text-4 cus-color">
						Don’t Stop Your Time. Just Stope your Time!
					</div>
					<div className="logo-timeStope">
						<img alt="logo timestope" src={require('../../assets/images/logo-timestope.png')} />
					</div>

					<div className="row custom-row-info">
						<div className='col-lg-3 col-md-6 col-12 custom-col-info'>
							<div className="class-icon icon-time-search">
								<img alt="time search" src={require('../../assets/images/time-search.png')} />
							</div>
							<div className="title-icon">TimeStope</div>
							<div className="text-icon text-icon-1">Collecting TIME </div>
							<div className="text-icon text-icon-2">with our TimeStope</div>
						</div>
						<div className='col-lg-3 col-md-6 col-12 custom-col-info'>
							<div className="class-icon icon-time-search">
								<img alt="dailye attendance" src={require('../../assets/images/daily-attendance.png')} />
							</div>
							<div className="title-icon">Daily Attendance</div>
							<div className="text-icon text-icon-1">Collecting TIME by</div>
							<div className="text-icon text-icon-2">checking your daily attendance.</div>
						</div>
						<div className='col-lg-3 col-md-6 col-12 custom-col-info'>
							<div className="class-icon icon-time-search">
								<img alt="time mining" src={require('../../assets/images/time-mining.png')} />
							</div>
							<div className="title-icon">TIME Collecting </div>
							<div className="text-icon text-icon-1">More and more</div>
							<div className="text-icon text-icon-2">various activities for your</div>
							<div className="text-icon text-icon-3">endless TIME Collecting.</div>
						</div>

						<div className='col-lg-3 col-md-6 col-12 custom-col-info'>
							<div className="class-icon icon-time-search">
								<img alt="time mining" src={require('../../assets/images/61.png')} />
							</div>
							<div className="title-icon">Team Benefits</div>
							<div className="text-icon text-icon-1">100 attendance points</div>
							<div className="text-icon text-icon-2">Get TimeStope stock</div>
							<div className="text-icon text-icon-2">Open a community in TimeStope</div>
							<div className="text-icon text-icon-2">Get Timestope goods for free</div>
							<div className="text-icon text-icon-2">Get invitation to our events</div>
							<div className="text-icon text-icon-2">
								<Link className="btn-join-our-team" to="/external-team">
									Details
								</Link>
							</div>

						</div>

					</div>
				</div>
				<div id="section-contact-us" className="section-contact-us">
					<div className="title-contact-us">Contact us</div>
					<div className="text-contact">Drop us a line! We will reach out to you & answer all your questions personally.</div>
					<div className="text-contact">Recurring questions will be added to the FAQ page.
					</div>

					{/* form */}
					<div className="formform-contact-us">
						<div className="row-name">
							<div className="first-name">
								<input type="text" placeholder="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
							</div>
							<div className="first-name">
								<input type="text" placeholder="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
							</div>
						</div>

						<div className="email-address">
							<input type="text" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>

						<div className="your-comment">
							<textarea value={content} rows="4" cols="50" placeholder="Your Comment" onChange={(e) => setContent(e.target.value)} />
						</div>

						<div className="center-btn" >
							<div className="btn-submit" style={{ backgroundColor: !preventClick ? '#06243c' : "#929497" }} onClick={() => !preventClick && _onHanldeSubmit()}> Send </div>
						</div>

					</div>
				</div>
			</div>

			{/* Render Footer */}
			<FooterComponent />
		</div>
	)
}
export default MainComponent;
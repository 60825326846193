import React, {useState, useEffect} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import _ from 'lodash';

function PrivacyNoticeComponent(props){
    
	return(
		<div className="App">
		{/* Render Header */}
	    <HeaderComponent activePage={"10"} />
		{/* Render Body */}
	    <div className={"privacy-notice"}>
            <div class="container hide-overflow">
                <div class="row justify-content-center align-items-center ">
                    <div id="heroCTAs" class="col-md-10 col-lg-9 col-xl-8 text-center container-box">
                        <p class="container-title">Privacy Notice </p>
                    </div>
                </div>
                <div class="container-contents justify-content-center align-items-left">
                <p>Effective: Oct 10, 2020</p>
                <p>This Privacy Notice is a supplement to Timestope llc’s which can be found on our website at <a href="https://timestope.mojaik.com/">timestope.mojaik.com/</a>. This applies to all visitors, users and other who reside in the State of California (“consumers” or “you”). Timestope llc has incorporated this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and other applicable laws. The terms defined in the CCPA have the same meaning when used in this notice.</p>
                <p><b>INFORMATION WE COLLECT</b></p>
                <p>We may collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”) through our website. We may have collected personal information from consumers since Oct 10, 2020 in the following categories:</p>
                <div class="show-overflow">
                <table class="foo-table ninja_footable foo_table_41749 ninja_table_unique_id_2002259804_41749 ui table nt_type_ajax_table selectable striped vertical_centered footable-paging-right ninja_table_search_disabled footable footable-1 breakpoint-lg">
                    <thead>
                        <tr class="footable-header thead-title">
                            <th class="ninja_column_0 ninja_clmn_nm_category footable-first-visible" scope="col table_call">Category</th>
                            <th class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected" scope="col table_call">Examples (not all will be collected)</th>
                            <th class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible" scope="col table_call">Collected</th>
                    </tr>
                    </thead>
                    <tbody class="tbody-border">
                        <tr class="ninja_table_row_0 nt_row_id_91">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Identifier</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">A real name, email address, Birthday.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">Yes</td>
                        </tr>
                        <tr class="ninja_table_row_1 nt_row_id_92 tbody-setBackground">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80 (e))</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">A real name.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">Yes</td>
                        </tr>
                        <tr class="ninja_table_row_2 nt_row_id_93">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Protected classifications characteristics under California or Federal law</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Age ,nationality, citizenship, sex .</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">Yes</td>
                        </tr>
                        <tr class="ninja_table_row_3 nt_row_id_94 tbody-setBackground">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Commercial Information</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">No</td>
                        </tr>
                        <tr class="ninja_table_row_4 nt_row_id_95">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Biometric Information</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Genetic, physiological, behavioral, biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as fingerprints, face prints and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns and sleep, health, or exercise data.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">No</td>
                        </tr>
                        <tr class="ninja_table_row_5 nt_row_id_96 tbody-setBackground">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Internet or other similar network activity</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Browsing history, search history, information on a consumer’s interaction with a website, application or advertisement.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">No</td>
                        </tr>
                        <tr class="ninja_table_row_6 nt_row_id_97">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Geolocation Data</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Physical location or movements.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">Yes</td>
                        </tr>
                        <tr class="ninja_table_row_7 nt_row_id_98 tbody-setBackground">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Sensory Data</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Audio, electronic, visual, thermal, olfactory or similar information.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">No</td>
                        </tr>
                        <tr class="ninja_table_row_8 nt_row_id_99">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Professional or employment related information</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Current or past job history or performance evaluations.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">No</td>
                        </tr>
                        <tr class="ninja_table_row_9 nt_row_id_100 tbody-setBackground">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Non-public education information (per the Family Education Rights and Privacy Act  (20 U.S.C. Section 1232g, 34 C.F.R. part 99)</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grade, transcripts, class lists, student schedules, student identification codes, and student financial information or student disciplinary records.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">No</td>
                        </tr>
                        <tr class="ninja_table_row_10 nt_row_id_101">
                            <td class="ninja_column_0 ninja_clmn_nm_category footable-first-visible table_call">Inferences drawn from other personal information</td>
                            <td class="ninja_column_1 ninja_clmn_nm_examples_not_all_will_be_collected table_call">Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior attitudes, intelligence, abilities and aptitudes.</td>
                            <td class="ninja_column_2 ninja_clmn_nm_collected footable-last-visible table_call">No</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p>&nbsp;</p>
                <p><b>Personal information does not include:</b></p>
                <ul>
                    <li>Publicly available information from government records</li>
                    <li>De-identified or aggregated consumer information</li>
                    <li class="font-bold">We do not collect personal information such as:
                        <ul>
                            <li>postal address, unique personal identifier, online identifier, Internet Protocol Address, account name, Social Security number, driver’s license number, passport number, or other similar identifiers.</li>
                        </ul>
                    </li>
                    <li>Information excluded from the CCPA’s scope, such as:
                        <ul>
                            <li>Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California confidentiality of Medical Information Act (CMIA or clinical trial data;</li>
                            <li>Personal Information covered by certain sector specific privacy laws, including the Fair Credit Report Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA) and the Driver’s Privacy Protection Act of 1994.</li>
                        </ul>
                    </li>
                </ul>
                <p><b>Use of Personal Information</b></p>
                <p>Performing services, including maintaining or providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, tracking the usage of our application. Even if we do not retain information, it still will be transmitted to our servers initially and stored long enough to process.</p>
                <p>We also use information that you provide us, including any personal information:</p>
                <ul>
                    <li>To give you information on our products and services.</li>
                    <li>To provide you with information you’ve requested regarding our products and services.</li>
                    <li>To fulfill any other purpose for which you provide it.</li>
                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including billing and collection.</li>
                    <li>For any purpose with your consent.</li>
                </ul>
                <p>Timestope Service may use personal information for the following business purposes:</p>
                <ul>
                    <li>To create, maintain and secure your account with us.</li>
                    <li>To provide support to your inquiries by validating your personal information.</li>
                </ul>
                <p><b>Sharing Personal Information</b></p>
                <p>Timestope Service, will never disclose your personal information to a third party for a business purpose.</p>
                <p><b>Sale of Personal Information</b></p>
                <p>Timestope Service has not sold personal information since Oct 10, 2020 which is the effective date of the CCPA.</p>
                <p><b>Your California Privacy Rights</b></p>
                <p>If you are a California resident, you have the right to:</p>
                <ol>
                <li>Request we disclose to you free of charge the following information covering the 12 months preceding your request:
                    <ol type="a">
                        <li>The categories of personal information about you that we collected.</li>
                        <li>The category of sources from which the personal information was collected.</li>
                        <li>The purpose for collecting personal information about you.</li>
                        <li>The categories of third parties to whom we disclosed personal information about you and the categories of personal information that was disclosed and the purpose for disclosing the personal information about you.</li>
                        <li>The specific pieces of personal information we collected about you.</li>
                    </ol>
                    </li>
                    <li>Request we delete your personal information, unless the CCPA recognizes and exception.</li>
                    <li>Be free from unlawful discrimination for exercising your rights under the CCPA.</li>
                </ol>
                <p>We will acknowledge receipt of your request and advise you how long we expect it will take to respond once we have verified your identity. Requests for specific pieces of personal information will require additional information to verify your identity.</p>
                <p>If you submit a request on behalf of another person, we may require proof of authorization and verification if identity directly from the person for whom you are submitting a request.</p>
                <p>In some instances, we may not be able to honor your request. For example, we will not honor your request if we cannot verify your identity or if we cannot verify that you have the authority to make a request on behalf of another person. Additionally, we will not honor your request where an exception applies, such as where the disclosure of personal information would adversely affect the rights and freedoms of another consumer or where the personal information that we maintain about you is not subject to the CCPA’s access or deletion rights.</p>
                <p>We will advise you in our response if we are not able to honor your request. We will not provide social security numbers, driver’s license numbers or government issued identification number, financial account numbers, account passwords or security questions and answers, or any specific pieces of information if the disclosure presents the possibility of unauthorized access that could result in identity theft or fraud or unreasonable risk to data or systems and network security.</p>
                <p>We will work to process all verified requests within 45 days pursuant to the CCPA. If we need an extension for an additional 45 days in order to process the request, we will provide you with an explanation for the delay.</p>
                <p><b>How to exercise your rights:</b></p>
                <p>Complete the Online Personal Information Request form located here, and either email or print and mail to:</p>
                <p>Timestope llc<br></br> 33 35 37 Hung Phuoc 4, District 7, Ho Chi Minh City, Vietnam</p>
                <p>E-mail:<br></br><a href="timestope.help@gmail.com" target="_blank" rel="noopener noreferrer">timestope.help@gmail.com</a></p>
                <p>If you have questions about this CCPA please call our toll free number +84 90-333-1004 and leave a message for Compliance Privacy.</p>
                </div>
            </div>
        </div>
        <FooterComponent />
    	</div>
	)
}
export default PrivacyNoticeComponent;
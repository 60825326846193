import React from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';

const listDataClassA = [
    {image  : require('../../assets/images/21.png'), name : 'image class A'},
    {image  : require('../../assets/images/21.png'), name : 'image class A'},
    {image  : require('../../assets/images/21.png'), name : 'image class A'},
    {image  : require('../../assets/images/21.png'), name : 'image class A'},
    {image  : require('../../assets/images/21.png'), name : 'image class A'},
    {image  : require('../../assets/images/21.png'), name : 'image class A'},
]

function InvestorComponent(){
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"05"} />
		{/* Render Body */}
	    <div className={"investor"} id="investor">
            <div className="container-investor">
                {/* CLASS C: 51% */}
                <div className="title title-class-c">CLASS C: <span className="color-font-weight">51%</span></div>
                <div className="line-width"></div>
                <div className="avatar-CEO"><img alt={'Seung Il Chung'} src={require('../../assets/images/21-A.png')} /></div>
                <div className="name-CEO">Seung Il Chung</div>

                <div className="line-height"></div>

                {/* <div className="btn-center">
                    <div className="btn-invest"> Invest </div>
                </div> */}
                
                {/* CLASS B: 40% */}
                <div className="title title-class-b">CLASS B: <span className="color-font-weight">40%</span></div>
                <div className="line-width"></div>

                <div className="row custom-row-class-b">
                    <div className="col-lg-3 col-sm-6 col-6 customize-col-class-b">
                        <img alt={'Seung Il Chung'} src={require('../../assets/images/21.png')} />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-6 customize-col-class-b">
                        <img alt={'Seung Il Chung'} src={require('../../assets/images/21.png')} />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-6 customize-col-class-b">
                        <img alt={'Seung Il Chung'} src={require('../../assets/images/21.png')} />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-6 customize-col-class-b">
                        <img alt={'Seung Il Chung'} src={require('../../assets/images/21.png')} />
                    </div>
                </div>

                <div className="line-height"></div>

                {/* CLASS A: 9% */}
                <div className=" title title-class-a">CLASS A: <span className="color-font-weight">9%</span></div>
                <div className="line-width"></div>

                <div className="list-class-a">
                    <div className="row custom-row-class-a">
                        {listDataClassA.map((item,ind) => {
                            return(
                                <div className="customize-col-class-a" key={ind}>
                                    <img alt={item.name} src={item.image} />
                                </div>
                            )
                        })}
                    </div>

                    <div className="row custom-row-class-a">
                        {listDataClassA.map((item,ind) => {
                            return(
                                <div className="customize-col-class-a" key={ind}>
                                    <img alt={item.name} src={item.image} />
                                </div>
                            )
                        })}
                    </div>

                    <div className="row custom-row-class-a">
                        {listDataClassA.map((item,ind) => {
                            return(
                                <div className="customize-col-class-a" key={ind}>
                                    <img alt={item.name} src={item.image} />
                                </div>
                            )
                        })}
                    </div>

                    <div className="row custom-row-class-a">
                        {listDataClassA.map((item,ind) => {
                            return(
                                <div className="customize-col-class-a" key={ind}>
                                    <img alt={item.name} src={item.image} />
                                </div>
                            )
                        })}
                    </div>

                    <div className="row custom-row-class-a">
                        {listDataClassA.map((item,ind) => {
                            return(
                                <div className="customize-col-class-a" key={ind}>
                                    <img alt={item.name} src={item.image} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                
                
            </div>
        </div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default InvestorComponent;
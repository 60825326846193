import React from 'react';
import { Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import '../scss/main.scss';
import MainComponent from '../modules/layouts';
import FeeChainDocumentComponent from '../modules/pages/FeeChainDocument';
import DownloadComponent from '../modules/pages/downloadComponent';
import InvestorComponent from '../modules/pages/investorComponent';
import ShopComponent from '../modules/pages/shop';
import DetailShopComponent from '../modules/pages/detailShop';
import CheckoutComponent from '../modules/pages/checkout';

import DetailSearchComponent from '../modules/pages/searchDetail';
import TimeSearchComponent from '../modules/pages/timeSearch';
import TeamMemberComponent from '../modules/pages/teamMember';
import OutsideTeam from '../modules/pages/outsideTeamMember';
import FAQComponent from '../modules/pages/FAQ';
import HistorySearchComponent from '../modules/pages/historySearch';
import WhitePaperComponent from '../modules/pages/whitePaper';
import ResetAccountComponent from '../modules/pages/ResetAccountComponent';
import PrivacyNoticeComponent from '../modules/pages/PrivacyNoticeComponent';
import privacyPolicyComponent from '../modules/pages/privacyPolicy';
import PrivacyComponent from '../modules/pages/PrivacyComponent';
import DeleteAccountComponent from '../modules/pages/deleteAccount';
import 'bootstrap/dist/css/bootstrap.css';

import DetailSearchNewComponent from '../modules/pages/searchDetailNewComponent';
import TimeSearchNewComponent from '../modules/pages/searchNewComponent';

// RUN website timestope.com

function App() {
  return (
    <Router>
        <Switch>
            <Route exact path='/' component={MainComponent} />
            
            <Route  path='/feechain-document' component={FeeChainDocumentComponent} />
            {/* <Route  path='/download/:userId' component={DownloadComponent} /> */}
            <Route  path='/download' component={DownloadComponent} />
            {/* route search */}
            {/* <Route  path='/time-search/:id' component={DetailSearchComponent} /> */}
            {/* <Route  path='/time-search' component={TimeSearchComponent} /> */}
            <Route  path='/support' component={MainComponent} />

            <Route  path='/internal-team' component={TeamMemberComponent} />
            <Route  path='/external-team' component={OutsideTeam} />
            {/* <Route  path='/investor' component={InvestorComponent} /> */}
            {/* route shop */}
            {/* <Route  path='/goods/checkout' component={CheckoutComponent} />
            <Route  path='/goods/:id' component={DetailShopComponent} />
            <Route  path='/goods' component={ShopComponent} /> */}

            <Route  path='/faq' component={FAQComponent} />
            <Route  path='/history-of-search' component={HistorySearchComponent} />
            {/* <Route  path='/white-paper' component={WhitePaperComponent} /> */}
            <Route  path='/reset-account' component={ResetAccountComponent} />
            <Route  path='/privacy-notice' component={PrivacyNoticeComponent} />
            <Route  path='/privacy' component={PrivacyComponent} />
            <Route  path='/privacy-policy' component={privacyPolicyComponent} />
            <Route  path='/delete-account' component={DeleteAccountComponent} />

            <Route exact path='/:userId' component={MainComponent} />
        </Switch>
    </Router>
  );
}

//RUN domain search.timestope.com

// function App() {
//   return (
//     <Router>
//         <Switch>
//             <Route exact path='/' component={TimeSearchNewComponent} />
//             <Route exact path='/:id' component={DetailSearchNewComponent} />
//         </Switch>
//     </Router>
//   );
// }

export default App;

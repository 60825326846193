import React from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import { Link } from 'react-router-dom';
import{ListItemInShop} from './listdata';
import {useSelector} from 'react-redux';

function ShopComponent(){
    const {amount} = useSelector(state => state.shop);
    // console.log('localStorage', localStorage)
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"06"} />
		{/* Render Body */}
	    <div className={"shop"} id="shop"> 
            <div className="container-cart">
                <Link className="custom-cart" to='/goods/checkout'>
                    <img alt="Icon ao" className="image-cus" src={require('../../assets/images/49.png')} />
                    <div className="amount-order">{amount}</div>
                </Link>
                
            </div>
            <div className="container-shop">
                <div className="row">

                    {ListItemInShop.slice(0,3).map((item, index) => {
                        return(
                            <div className="col-lg-4 col-sm-6 col-12 customize-col" key={index}>
                                <Link className="container-image" to={item.link}>
                                    <img alt={item.name} className="image-cus" src={item.image} />
                                </Link>
                                <div className="content">
                                    <img alt="logo" className="logo-timestope" src={require('../../assets/images/2.png')} />
                                    <div className="text-shirt">{item.name}</div>
                                    <div className="money">${item.price}</div>
                                </div>
                            </div>
                        )
                    })}

                    { ListItemInShop.slice(3,4).map((item, index) => {
                        return(
                            <div className="col-lg-4 col-sm-6 col-12 customize-col" key={index}>
                                <Link className="container-combo container-mu" to={item.link}>
                                    <img alt={item.name} className="image-cus-mu" src={item.image} />
                                </Link>
                                <div className="content">
                                    <img alt="logo" className="logo-timestope" src={require('../../assets/images/2.png')} />
                                    <div className="text-shirt">{item.name}</div>
                                    <div className="money">${item.price}</div>
                                </div>
                            </div>
                        )
                    })}
                    
                    <div className="col-lg-8 col-sm-12 col-12 customize-col">
                        
                        {ListItemInShop.slice(4,5).map((item,ind) => {
                            return(
                                <div  key={ind}>
                                    <Link className="container-combo combo-image" to={item.link}>
                                        {item.listImage.map((it,index) => {
                                            return(
                                                <img alt={it.name} key={index} className={it.class} src={it.image} />
                                            )
                                        })}
                                    </Link>
                                    <div className="content">
                                        <img alt="logo" className="logo-timestope" src={require('../../assets/images/2.png')} />
                                        <div className="text-shirt">{item.name}</div>
                                        <div className="money">${item.price}</div>
                                    </div>
                                </div>
                            )
                        })}
                        
                    </div>
                </div>
            </div>
        </div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default ShopComponent;
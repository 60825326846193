import { combineReducers } from 'redux';
import { persistReducer  } from 'redux-persist';
import localForage from 'localforage';
import screenReducer from './reducers/screenReducer';
import settingReducer from './reducers/settingReducer';
import shopReducer from './reducers/shopReducer';

localForage.config({
    name: 'WebTime Habros',
    storeName: 'keyWebTime'
});

const rootPersistConfig = {
    version: 0,
    key: 'keyWebTime',
    storage: localForage,
    // whitelist: ['shop'],
};

const rootReducer = combineReducers({
    screen : screenReducer,
    setting : settingReducer,
    shop : shopReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
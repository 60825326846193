import React from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import {listDataCeo, listDevelopers, listMarketings, listDesigners} from './listdata'

function TeamMemberComponent(){
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"04"} />
		{/* Render Body */}
	    <div className={"team"} id="team">
			<div className="container-download">
				<div className="title-TimeStope"><span>TimeStope’s Team</span><img alt="TimeStope’s Team" src={require('../../assets/images/1.png')} /></div>
				<div className="btn-center">
					<a target="_blank" href="https://docs.google.com/forms/d/1P9e5lJ989iMqYLFhbmISsJ83iEUayC4Kn0gASkQqjPg/viewform?edit_requested=true" className="link-join-team">
						<div className="btn-join-team">
							Join Our Team
						</div>
					</a>
				</div>
				{listDataCeo.map((item,index) => {
					return(
						<div className='row custom-row' key={index}>
							<div className="col-lg-4 col-sm-5 col-12 customize-col-left">
								<img alt={item.name} src={item.avatar} />
							</div>
							<div className="col-lg-8 col-sm-7 col-12 customize-col-right">
								<div className="name">{item.name}</div>
								<div className="position">{item.position}</div>
								<div className="infomation-1">{item.informationOne}</div>
								{item.informationTwo !== '' ? <div className="infomation-2">{item.informationTwo}</div> : null}
							</div>
						</div>
					)
				})}

				<div className="team-developers">
					<div className="title">Developers</div>
					<div className='row custom-row-dev'>
						{listDevelopers.map((item,ind) => {
							return(
								<div className="col-lg-4 col-sm-4 col-6 customize-col-dev" key={ind}>
									<img alt={item.name} src={item.avatar} />
									<div className="name">
										<span className="name_custome">{item.name}</span> 
										{item.address !== '' &&  <span>
											<span className="line__height">{" | "}</span> 
											<span className="name_address">{item.address}</span>
										</span> }
									</div>
									<div className="position">{item.position}</div>
								</div>
							)
						})}
					</div>
				</div>
				
				<div className="team-marketing">
					<div className="title">Marketing Managers</div>
					<div className='row custom-row-dev'>
						{listMarketings.map((item,ind) => {
							return(
								<div className="col-lg-4 col-sm-4 col-6 customize-col-dev" key={ind}>
									<img alt={item.name} src={item.avatar} />
									<div className="name">
										<span className="name_custome">{item.name}</span> 
										{item.address !== '' &&  <span>
											<span className="line__height">{" | "}</span> 
											<span className="name_address">{item.address}</span>
										</span> }
										
									</div>
									<div className="position">{item.position}</div>
								</div>
							)
						})}
					</div>
				</div>

				<div className="team-designers">
					<div className="title">Designers</div>
					<div className='row custom-row-dev'>
						{listDesigners.map((item,ind) => {
							return(
								<div className="col-lg-4 col-sm-4 col-6 customize-col-dev" key={ind}>
									<img alt={item.name} src={item.avatar} />
									<div className="name">
										<span className="name_custome">{item.name}</span> 
										<span className="line__height">{" | "}</span> 
										<span className="name_address">{item.address}</span>
									</div>
									<div className="position">{item.position}</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="team-designers">
					<div className="title">Our Team</div>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-12">
						<img alt="image our team 1" style={{width : '100%', marginBottom : 30}} src={require('../../assets/images/TEAM_1.png')} />
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12">
						<img alt="image our team 1" style={{width : '100%', marginBottom : 30}} src={require('../../assets/images/TEAM_2.png')} />
						</div>
					</div>
				</div>
				
			</div>



		</div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default TeamMemberComponent;
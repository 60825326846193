import React, {useState, useEffect} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import axios from 'axios';
import {config} from '../../lib/config';

import Select from 'react-select'
import countryList from 'react-select-country-list';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import _ from 'lodash';

import ReCAPTCHA from 'react-grecaptcha'
let options = countryList().getData();

function ResetAccountComponent(props){
    const [id, setId] = useState('');
    const [witness, setWitness] = useState('');

    const [gender, setGender] = useState(1);
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [nameEng, setNameEng] = useState('');
    const [date, setDate] = useState('');
    const [valueDate, setValueDate] = useState(null);

    const [countryName, setCountryName] = useState(null);
    const [country, setCountry] = useState(null);
    const [ preventClick, setPreventClick ] = useState(false);

    const [ checkCaptCha, setCheckCaptCha ] = useState(false);
    const [ key, setKey ] = useState('');

    const changeHandler = (value) => {
        setCountry(value.value);
        setCountryName(value)
    }

    const onChange = (dates) => {
        const parseDate = moment(dates).format('YYYY-MM-DD');
        setDate(parseDate);
        setValueDate(dates);
    }

    const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
    }
    
    const callback = (response) =>  {
        // console.log('callback ======', response);
        setCheckCaptCha(true);
    };
    const expiredCallback = (expired)  => {
        // console.log('expired ====', expired);
        setCheckCaptCha(false);
    };
   
    const _onHanldeSubmit = async() => {
        
        try{ 
			if(_.trim(id) === '') return alert('Please enter your id.');
			// else if(_.trim(witness) === '')	return alert('Please enter your witness.');
			else if(_.trim(country) === '')	return alert('Please enter your country.');
			else if(_.trim(name) === '')	return alert('Please enter your name.');
			else if(_.trim(nameEng) === '')	return alert('Please enter your name english.');
            else if(_.trim(mail) === '')    return alert('Please enter your mail.');
            else if (!validateEmail(mail))  return alert('Invalid your email.');
            else if (_.trim(date) === '')   return alert('Please choose your birth day.');
            else if (_.trim(key) === '')   return alert('Please enter your code.');
            else{
                setPreventClick(true)
                const json = {
                    userId : id,
                    // witness,
                    nationality : country,
                    name : name,
                    birthName : nameEng,
                    gender : gender,
                    email : mail,
                    birthday : date,
                    key : key
                }
                // console.log('json ====', json);

                const requestApi = await axios.post(config.server_timeStope + '/v1/resetAccount', json);
                // console.log('requestApi', requestApi);

                if(requestApi && requestApi.data && requestApi.data.success) {
                    setId('');
                    setName('');
                    setCountry(null);
                    setCountryName(null);
                    setNameEng('');
                    setDate('');
                    setValueDate(null);
                    setMail('');
                    setPreventClick(false);
                    
                    return alert(requestApi.data.message);
                }
                setPreventClick(false);
                
            }
			
        }
        catch(e){
			setPreventClick(false);
			alert(e.response.data.message);
		}
    }
	return(
		<div className="App">
		{/* Render Header */}
	    <HeaderComponent activePage={"10"} />
		{/* Render Body */}
	    <div className={"reset-account"} id="reset">
            <div className="container-reset">

                <div className="userId">
                    <div className="text-id">  ID : </div>
                    <input type="text" placeholder="(from 5 to 11 characters)" value={id} onChange={(e) => setId(e.target.value)} />
                </div>

                {/* <div className="witness">
                    <div className="text-id"> witness : </div>
                    <input type="text" value={witness} onChange={(e) => setWitness(e.target.value)} />
                </div> */}
                <Select
                    placeholder="Select Country"
                    options={options}
                    value={countryName}
                    onChange={changeHandler}
                />

                <div className="Name">
                    <div className="text-name-eng">  Gender : </div>
                    <div className="male">
                        <input type="radio" name="gender" value={'1'} onChange={(e) => setGender(1)} checked={gender ===1 ? true : false} />
                        <div className="text-name-eng">  Male </div>
                    </div>

                    <div className="male">
                        <input type="radio" name="gender" value={'2'} onChange={(e) => setGender(2)} checked={gender === 2 ? true : false}/>
                        <div className="text-name-eng">  Female </div>
                    </div>

                </div>

                <div className="Name-country">
                    <div className="text-name-country"> Name {country ? `(${country})` : null } :  </div>
                    <input type="text" placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div className="Name-eng">
                    <div className="text-name-eng">  Name (ENG) :  </div>
                    <input type="text" placeholder="" value={nameEng} onChange={(e) => setNameEng(e.target.value)} />
                </div>

               
                <div className="birth-day">
                    <div className="text-birth-day"> Birth of Date : </div>
                    <DatePicker
                        onChange={onChange}
                        value={valueDate}
                        format="y-MM-dd"
                    />
                </div>

                <div className="mail">
                    <div className="text-mail"> Mail : </div>
                    <input type="text" placeholder="" value={mail} onChange={(e) => setMail(e.target.value)} />
                </div>

                <div className="mail">
                    <div className="text-mail"> Code : </div>
                    <input type="text" placeholder="" value={key} onChange={(e) => setKey(e.target.value)} />
                </div>

                <ReCAPTCHA
                    sitekey="6Lef69sZAAAAAI8nPW0UJmA7nnQgEUqRVCl5svCy"
                    callback={callback}
                    expiredCallback={expiredCallback}
                    locale="en"
                />

                <div className="container-btn-submit">
                    <div className="btn-submit" style={{backgroundColor : !preventClick && checkCaptCha ? '#06243c' : "#929497"}} onClick={() => !preventClick && checkCaptCha && _onHanldeSubmit()}> Submit </div>
                </div>
               
            </div>
        </div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default ResetAccountComponent;
import React from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';

function HistorySearchComponent(){
	return(
		<div className="App">
		{/* Render Header */}
	    <HeaderComponent activePage={"08"} />
		{/* Render Body */}
	    <div className={"history"} id="history">
            <div className="container-history">
				<div className="title-history">
					<span>The History Of Search Engines</span>
					<img alt="TimeStope’s Team" src={require('../../assets/images/1.png')} />
				</div>

				<img alt="TimeStope’s Team" className="image-desktop" src={require('../../assets/images/history-1.png')} />
				<img alt="TimeStope’s Team" className="image-desktop" src={require('../../assets/images/history-2.png')} />

				<img alt="TimeStope’s history one" className="image-mobile" src={require('../../assets/Image_Mobile/History_mobile_1.png')} />
				<img alt="TimeStope’s history two" className="image-mobile" src={require('../../assets/Image_Mobile/History_mobile_2.png')} />
				<img alt="TimeStope’s history three" className="image-mobile" src={require('../../assets/Image_Mobile/History_mobile_3.png')} />
			</div>
		</div>
		{/* Render Footer */}
	    <FooterComponent />
    	</div>
	)
}
export default HistorySearchComponent;
import {ADD_TO_CART, REMOVE_ITEM_IN_CART, RESET_ITEM_IN_SHOP} from '../actions/shopActions';
const initial = {
    listOrder : [],
    amount : 0 ,// amountAllProduct
    totalMoney : 0,
}
export default function(state = initial, action){
    // console.log('action ==== ', action);
    switch (action.type) {
        case ADD_TO_CART :
            let data = [], amountAllProduct = 0, moneyAllProduct = 0;
            
            // check list order !== 0
            // if(state.listOrder.length !== 0) {
            //     const findItemInListOrder = [...state.listOrder].findIndex(item => item.id === action.data.id );
            //     if(findItemInListOrder !== -1){
            //         data = [...state.listOrder].map(item => {
            //             if(item.id === action.data.id){
            //                 item.amount = item.amount + action.data.amount;
            //                 item.size = action.data.size;
            //                 return item;
            //             } 
            //             return item;
            //         });
            //     }
            //     // if not found item in list, I will push action.data into listOrder
            //     else{
            //         data = [...state.listOrder].concat(action.data);
            //     }
            // }
            // //if listOrder=0 , I will push action.data into listOrder
            // else{
            //     data = state.listOrder.concat(action.data);
            // }
            // handle caculate amount all product.

            data = state.listOrder.concat(action.data);

            data.map(item => {
                amountAllProduct = amountAllProduct + item.amount;
                moneyAllProduct = moneyAllProduct + parseInt(item.price)* item.amount;
                return item;
            });


            console.log('listOrder ===>' , data);
            console.log('moneyAllProduct ===>' , moneyAllProduct);
            
            return{
                ...state,
                listOrder : data,
                amount : amountAllProduct,
                totalMoney : moneyAllProduct
            }
        case REMOVE_ITEM_IN_CART : 
            let dataNew, amountRemain = 0, moneyRemain= 0;
            dataNew = [...state.listOrder].filter(item => item.id !== action.data.id);
            // console.log('datanew ====>', dataNew);

            dataNew.map(item => {
                amountRemain = amountRemain + item.amount;
                moneyRemain = moneyRemain + parseInt(item.price)* item.amount;
                return item;
            });
            return{
                ...state,
                listOrder : dataNew,
                amount : amountRemain,
                totalMoney : moneyRemain
            }
        case RESET_ITEM_IN_SHOP :
            return{
                ...state,
                listOrder : [],
                amount : 0,
                totalMoney : 0
            }
        default:
            return state
    }
}
import React, {useState} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';

const listSection = [
    {id : '1', title : ' How to install fee chain wallet '},
    {id : '2', title : ' How to create a wallet on the fee chain '},
    {id : '3', title : ' How to access your wallet from the website'},
    {id : '4', title : ' How to stake in Fee Chain'},
];

function WhitePaperComponent(){
    const [activeSection, setActiveSection] = useState("1");
    const handleClickSection = (id) => {
        setActiveSection(id);
    }
    const renderSection1 = () => {
        return( <> 
        <img alt="fcd001" src={require('../../assets/images/fcd001.png')} /> 
        <img alt="fcd002" src={require('../../assets/images/fcd002.png')} /> 
        <img alt="fcd003" src={require('../../assets/images/fcd003.png')} /> 
        <img alt="fcd004" src={require('../../assets/images/fcd004.png')} /> 
        <img alt="fcd004" src={require('../../assets/images/fcd004_1.png')} /> 
        <img alt="fcd005" src={require('../../assets/images/fcd005.png')} /> 
        <img alt="fcd006" src={require('../../assets/images/fcd006.png')} /> 
        </>  )
    }

    const renderSection2 = () => {
        return( <> 
            <img alt="fcd007" src={require('../../assets/images/fcd007.png')} /> 
            <img alt="fcd008" src={require('../../assets/images/fcd008.png')} /> 
            <img alt="fcd009" src={require('../../assets/images/fcd009.png')} /> 
            <img alt="fcd010" src={require('../../assets/images/fcd010.png')} /> 
            <img alt="fcd011" src={require('../../assets/images/fcd011.png')} /> 
            <img alt="fcd012" src={require('../../assets/images/fcd012.png')} /> 
            <img alt="fcd013" src={require('../../assets/images/fcd013.png')} /> 
            <img alt="fcd014" src={require('../../assets/images/fcd014.png')} /> 
            <img alt="fcd015" src={require('../../assets/images/fcd015.png')} /> 
            <img alt="fcd016" src={require('../../assets/images/fcd016.png')} /> 
            <img alt="fcd017" src={require('../../assets/images/fcd017.png')} /> 
            </>  )
    }

    const renderSection3 = () => {
        return( <> 
            <img alt="fcd018" src={require('../../assets/images/fcd018.png')} /> 
            <img alt="fcd019" src={require('../../assets/images/fcd019.png')} /> 
            <img alt="fcd020" src={require('../../assets/images/fcd020.png')} /> 
            <img alt="fcd021" src={require('../../assets/images/fcd021.png')} /> 
            <img alt="fcd022" src={require('../../assets/images/fcd022.png')} /> 
            <img alt="fcd023" src={require('../../assets/images/fcd023.png')} /> 
            <img alt="fcd024" src={require('../../assets/images/fcd024.png')} /> 
            </>  )
    }

    const renderSection4 = () => {
        return( <> 
            <img alt="fcd025" src={require('../../assets/images/fcd025.png')} /> 
            <img alt="fcd026" src={require('../../assets/images/fcd026.png')} /> 
            <img alt="fcd027" src={require('../../assets/images/fcd027.png')} /> 
            <img alt="fcd028" src={require('../../assets/images/fcd028.png')} /> 
            <img alt="fcd029" src={require('../../assets/images/fcd029.png')} /> 
            <img alt="fcd030" src={require('../../assets/images/fcd030.png')} /> 
            <img alt="fcd031" src={require('../../assets/images/fcd031.png')} /> 
            <img alt="fcd032" src={require('../../assets/images/fcd032.png')} /> 
            </>  )
    }
  
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"02"} />
		{/* Render Body */}
		<div className={"white-paper"} id="feeChain-paper"> 
            <div className="container-white-paper">
				<div className="title-white-paper">
                    <div>
					<span className='text-header'>Fee Chain Wallet (</span>
                    <a href='https://download.timestope.com:6200/f64544568147d0419777697f573ef769/fee-chain-wallet-extension/1_0_0/fee_wallet.zip'>Download</a>
                    <span className='text-header'>)</span>
                    </div>
					<img alt="TimeStope’s Team" src={require('../../assets/images/1.png')} />
				</div>
                <div className="Content-white-paper">
                    <div className="row custom-row">
                        <div className="col-lg-3 col-sm-4 col-12 customize-col-left">
                            {listSection.map((item, ind) => {
                                return (
                                    <div className={`item ${activeSection === item.id ? 'active' : ''}`} key={ind} onClick={() => handleClickSection(item.id)}>
                                        <span className="color-font-weight">{item.id}.</span> {item.title}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-lg-9 col-sm-8 col-12 customize-col-right">
                            <div className='description'>
                                {activeSection === '1' && renderSection1()}
                                {activeSection === '2' && renderSection2()}
                                {activeSection === '3' && renderSection3()}
                                {activeSection === '4' && renderSection4()}
                            </div>
                        </div>
                    </div>
                    {/* <a className="download-blaa-white-paper" href="https://download.timestope.com:6200/f64544568147d0419777697f573ef769/fee-chain-wallet-extension/1_0_0/fee_wallet.zip" target="_blank">
                        <span className="text-download">Download Fee Chain Wallet</span>
                        <img alt="Icon download whitepaper" className="icon-download" src={require('../../assets/images/52.png')} />
                    </a> */}
                </div>
			</div>
		</div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default WhitePaperComponent;
import common from './common.json';

export default {
    en: {
        common: common,
    },
    ko: {
        common: common,
    },
    vi: {   
        common: common,
    }
}; 
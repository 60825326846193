export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ITEM_IN_CART = 'REMOVE_ITEM_IN_CART';
export const RESET_ITEM_IN_SHOP = 'RESET_ITEM_IN_SHOP';

export const shopActions = {
    addToCart,
    removeItemInCart,
    resetItemInShop
}

function addToCart(data){
    return {type : ADD_TO_CART, data}
}
function removeItemInCart(data){
    return {type : REMOVE_ITEM_IN_CART, data}
}

function resetItemInShop(){
    return {type : RESET_ITEM_IN_SHOP}
}
import React, {useState, useEffect} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';
import axios from 'axios';
import {config} from '../../lib/config';
import { useHistory } from 'react-router-dom';

function DownloadComponent(props){
    const [inviteCode, setInviteCode] = useState('');
    const [shoInvitationCode, setShowInvitationCode ] = useState(false);
    const history = useHistory();

    // const checkUser = async(userId) =>{
    //     const checkuser = await axios.post(config.server_timeStope + '/v1/time/user/check-userid', { "userId": userId });
    //     if(checkuser.data && checkuser.data.success){
    //         setShowInvitationCode(true);
    //         setInviteCode(userId);
    //         localStorage.setItem('useIdCode', userId)
    //     }
    // }
    useEffect(() => {
        // if(props && props.match && props.match.params && props.match.params.userId){
        //     checkUser(props.match.params.userId)
        // }
        // else 
        if(localStorage.getItem('useIdCode')){
            const userId = localStorage.getItem('useIdCode');
            setShowInvitationCode(true)
            setInviteCode(userId)
            // return history.push(`/download/${userId}`);
        }
    });
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"03"} />
		{/* Render Body */}
	    <div className={"download"} id="download">
            <div className="container-download row">
                <div className="col-lg-6 col-md-6 col-12 custom-col-left">
                    <img alt="app blaa chat" src={require('../../assets/images/22.png')} className="image-blaa-chat"/>
                </div>
                <div className="col-lg-6 col-md-6 col-12 custom-col-right">
                    
                    <div className="text-color-and-size">Download TimeStope the</div>
                    <div className="text-color-and-size">mobile app to start earning today!</div>
                    <div className="text-color-grey">You can download the mobile app below.</div>

                    <div className="container-download-app">
                        <a href="https://play.google.com/store/apps/details?id=com.timestope" target="_blank" className="download-ios">
                            <img alt="image google play" src={require('../../assets/images/11.png')} />
                        </a>
                        <a href="#" target="_blank" className="download-android">
                            <img alt="image app store" src={require('../../assets/images/12.png')} />
                        </a>
                    </div>

                    {shoInvitationCode && <>
                        <div className="text-invitation-code">Use your invitation Code: <img alt="Use your invitation Code" className="invitation-code" src={require('../../assets/images/54.png')} /></div>
                        <div className="input-code"><input type="text" readOnly placeholder="###ABCD" value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} /></div>
                        <div className="after-install">After installing Timestope App</div>
                    </>}
                    
                </div>
            </div>
        </div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default DownloadComponent;
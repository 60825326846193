import React, { Fragment, useState, useEffect } from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';
import { slide as Menu } from 'react-burger-menu';
// import TranslateLanguage from '../../lib/TranslateLanguage';
import { Link } from 'react-router-dom';

const listData = [
    { id: "01", text: 'HOME', link: '/', active: false },
    {
        id: '02', text: 'FEE CHAIN(BETA)', link: 'https', active: false,
        listItem: [
            {
                id: '021', text: 'EXPLORER', link: 'https://explorer.timestope.com',
            },
            {
                id: '022', text: 'STAKING', link: 'https://staking.timestope.com',
            },
            {
                id: '023', text: 'WALLET', link: '/feechain-document',
            },
        ]
    },
    { id: "03", text: 'DOWNLOAD', link: '/download', active: false },
    // {id : "03", text : 'TIME SEARCH', link : '/time-search',active : false},
    {
        id: '04', text: 'TEAM', link: '/#', active: false,
        listItem: [
            {
                id: '041', text: 'INTERNAL TEAM', link: '/internal-team',
            },
            {
                id: '042', text: 'EXTERNAL TEAM', link: '/external-team',
            },
        ]
    },
    // {id : "05", text : 'INVESTORS', link : '/investor',active : false},
    // {id : "06", text : 'GOODS', link : '/goods',active : false},
    { id: "07", text: 'FAQ', link: '/faq', classAcactivetive: false },
    { id: "08", text: 'HISTORY', link: '/history-of-search', active: false },
    // { id: "09", text: 'WHITEPAPER', link: '/white-paper', active: false },
    { id: "10", text: 'PRIVACY NOTICE', link: '/privacy-notice', active: false },
];

const HeaderComponent = (props) => {

    const [dataHeader, setDataHeader] = useState(listData);
    const [toggleItem_Fee, setToggleItem_Fee] = useState(false);
    const [toggleItem_Team, setToggleItem_Team] = useState(false);

    useEffect(() => {
        if (props && props.activePage) {
            const listNewClone = listData.map(item => {
                if (item.id === props.activePage) {
                    item.active = true;
                    return item;
                }
                else {
                    item.active = false;
                    return item;
                }
            });
            setDataHeader(listNewClone)
        }
    }, []);

    const [showScroll, setShowScroll] = useState(false);

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };

    window.addEventListener('scroll', checkScrollTop);
    window.onclick = (event) => {
        if (event.target.className === "item-sub" || event.target.className === "item-main" || event.target.className === "menu-item" || event.target.className === "menu-item active") {
            event.target.id == "menu02" ? setToggleItem_Team(false) : setToggleItem_Fee(false)
        }
        else {
            setToggleItem_Fee(false);
            setToggleItem_Team(false);
        }
    }

    return (
        <Fragment>
            {/* <TranslateLanguage direct={"MenuHeader.Home"}/> */}
            <div className={`menu-header`}>
                {/* for mobile */}
                <Menu>
                    {dataHeader.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                {item && item.listItem ?
                                    (item.text === "TEAM" ?
                                        <Fragment>
                                            <a onClick={() => setToggleItem_Team(!toggleItem_Team)} id={`${'menu' + item.id}`} className="item-main" > {item.text} </a>
                                            {item.active === true && <div className="underline-text" />}
                                            {toggleItem_Team && <ul className="sub-menu">
                                                {item.listItem.map((it, id) => {
                                                    return (
                                                        <Link key={id} to={`${it.link}`} className="item-sub">{it.text}</Link>
                                                    )
                                                })}
                                            </ul>}
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <a onClick={() => setToggleItem_Fee(!toggleItem_Fee)} id={`${'menu' + item.id}`} className="item-main" > {item.text} </a>
                                            {item.active === true && <div className="underline-text" />}
                                            {toggleItem_Fee && <ul className="sub-menu feeChain">
                                                {item.listItem.map((it, id) => {
                                                    if(it.text === "WALLET") return ( <Link key={id} to={`${it.link}`} className="item-sub">{it.text}</Link> )
                                                    return (
                                                        <a key={id} href={`${it.link}`} className="item-sub" target='_blank'>{it.text}</a>
                                                    )
                                                })}
                                            </ul>}
                                        </Fragment>
                                    )
                                    :
                                    <Link id="home" className={`${item.active === true ? 'menu-item active' : 'menu-item'}`} to={`${item.link}`}>{item.text}</Link>
                                }
                            </Fragment>
                        )
                    })}
                </Menu>
                {/* for web */}
                <Navbar expand="md">
                    <Link to="/" className="logo-timestope"><img alt="logo" src={require('../../assets/images/logo.png')} /></Link>
                    <Link to="/" className="logo__page-search"><img alt="logo" src={require('../../assets/images/57.png')} /></Link>
                    {/* <div></div> */}
                    {/* <Collapse isOpen={false} navbar className="customize-collapse"> */}
                    <Nav className="ml-auto customize-collapse" navbar>
                        {dataHeader.map((item, index) => {
                            return (
                                <NavItem key={index} className={`${item.active === true ? 'active' : ''}`}>
                                    {item && item.listItem ?
                                        (item.text === "TEAM" ?
                                            <Fragment>
                                                <a onClick={() => setToggleItem_Team(!toggleItem_Team)} id={`${'menu' + item.id}`} className="item-main" > {item.text} </a>
                                                {item.active === true && <div className="underline-text" />}
                                                {toggleItem_Team && <ul className="sub-menu">
                                                    {item.listItem.map((it, id) => {
                                                        return (
                                                            <Link key={id} to={`${it.link}`} className="item-sub">{it.text}</Link>
                                                        )
                                                    })}
                                                </ul>}
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <a onClick={() => setToggleItem_Fee(!toggleItem_Fee)} id={`${'menu' + item.id}`} className="item-main" > {item.text} </a>
                                                {item.active === true && <div className="underline-text" />}
                                                {toggleItem_Fee && <ul className="sub-menu feeChain">
                                                    {item.listItem.map((it, id) => {
                                                        if(it.text === "WALLET") return ( <Link key={id} to={`${it.link}`} className="item-sub">{it.text}</Link> )
                                                        return (
                                                            <a key={id} href={`${it.link}`} className="item-sub" target='_blank'>{it.text}</a>
                                                        )
                                                    })}
                                                </ul>}
                                            </Fragment>
                                        )
                                        :
                                        <Fragment>
                                            <Link to={`${item.link}`}>{item.text}</Link>
                                            {item.active === true && <div className="underline-text" />}
                                        </Fragment>
                                    }
                                </NavItem>
                            )
                        })}
                    </Nav>
                    {/* </Collapse> */}
                </Navbar>
            </div>
            {showScroll && <div className="scrollToTop" onClick={() => scrollTop()}>
                <img alt="image google play" src={require('../../assets/images/14.png')} />
            </div>}

        </Fragment>
    )
}
export default HeaderComponent;
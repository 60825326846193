import React, {Fragment } from 'react';

const FooterComponent = () => {
    return(
        <Fragment>
            <div className="footer" id='footer'>
                <div className="container-icon">
                    <a target="_blank" href="https://www.facebook.com/timestope"><img alt="time mining" src={require('../../assets/images/15.png')} /></a>
                    <a target="_blank" href="https://twitter.com/StopeTime"><img alt="time mining" src={require('../../assets/images/16.png')} /></a>
                    <a target="_blank" href="https://www.linkedin.com/company/timestope"><img alt="time mining" src={require('../../assets/images/17.png')} /></a>
                    <a target="_blank" href="https://www.instagram.com/timestope"><img alt="time mining" src={require('../../assets/images/18.png')} /></a>
                </div>
                <a href="/TimeStopeTermsOfUse.pdf" target="_blank" className="terms-of-use">TimeStope - Terms of Use</a>
                <div className="copy-right">Timestope llc 2020, TimeStope. All rights reserved.</div>
                <div className="copy-right">Timestope llc, 33 35 37 Hung Phuoc 4, P.Tan Phong, Quan 7, Vietnam</div>
            </div>
        </Fragment>
    )
}
export default FooterComponent;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/app.js';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { store } from './lib/createStore';
import { config as i18nextConfig } from './translations/index';
// import { BrowserRouter, Router } from 'react-router-dom';
// import { createBrowserHistory } from 'history';

i18next.init(i18nextConfig);

// var history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
      {/* <Router history={history}> */}
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      {/* </Router> */}
    </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

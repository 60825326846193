
export default function(state={language : 'en'}, action){
    switch (action.type) {
        case "CHANGE_LANGUAGE" :
            return{
                ...state,
                language: 'en'
            }
        default:
            return state
    }
}
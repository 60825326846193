import React, {useState} from 'react';
import HeaderComponent from '../layouts/headerComponent';
import FooterComponent from '../layouts/footerComponent';

const listFaq = [
	{
		id : '01',
		title : 'What is TimeStope ?',
		description : "TimeStope is a world of new currency system.",
		answer : ''
	},
	{
		id : '02',
		title : 'How to join TimeStope ?',
		description : 'You must be invited by the witness.',
		answer : ''
	},
	{
		id : '03',
		title : 'Who is Witness?',
		description : 'After you successfully signed up your account in TimeStope, you are called a witness.',
		answer : ''
	},
	{
		id : '04',
		title : 'What are the benefits of being a witness ?',
		description : 'You can get TIME everyday.',
		answer : ''
	},
	{
		id : '05',
		title : 'What is TIME?',
		description : 'TIME is a cryptocurrency used in TimeStope. ',
		answer : `The unit of TIME is τ. 1τ = 1 hour.`
	},
	{
		id : '06',
		title : 'How to make TIME ?',
		description : 'When you become a witness, you can gain TIME by spending your time during the Stopeterm.',
		answer : ``
	},
	{
		id : '07',
		title : 'How many TIME will I get ?',
		description : 'Everyday, you will obtain 60% (14.4τ), and distribute 40% (9.6τ) to your witness. ',
		answer : ``
	},

	{
		id : '08',
		title : 'How to earn more TIME ?',
		description : 'In TimeStope, TIME will be used like money. ',
		answer : ``
	},
	{
		id : '09',
		title : 'How TIME is used ?',
		description : 'In TimeStope, TIME will be used like money.',
		answer : ``
	},
	{
		id : '10',
		title : 'How long is the Stopeterm (TIME collecting period) ?',
		description : 'Stopeterm has 2-year validity period.',
		answer : ``
	},
	{
		id : '11',
		title : 'How to extend the Stopeterm ?',
		description : 'Every time a witness invites 250 people, the Stopeterm will be extended by 24 months, and the it increases even if the attendance score reaches a certain score. ',
		answer : ``
	},
	{
		id : '12',
		title : 'How to calculate attendance score ?',
		description : 'The attendance score is 100 points for initial participation. ',
		answer : `If you check attendance, your attendance score increases by 2 points. If you do not check attendance, your attendance score decreases by 3 points.`
	},
	{
		id : '13',
		title : 'What happens if you violate TimeStope’s policies ?',
		description : 'Your account will be blocked, and all assets will be confiscated.',
		answer : ``
	},
	{
		id : '14',
		title : 'How to recover your account ?',
		description : 'If you are over the Stopeterm, then you need to find a new witness to enable your account, but your attendance score will be 100, and you will lose your witness.',
		answer : `If attendance score becomes 0, you cannot enable your account, but you can send your TIME to chosen participant.`
	},
	{
		id : '15',
		title : 'Why is your account disabled ?',
		description : 'If there is no response to the notification three times, the account status will be processed as death, then you cannot use any service in TimeStope, but you can send your asset to other accounts.',
		answer : ``
	},
]

function FAQComponent(){
	const [toggleQuestion, setToggleQuestion] = useState();

	const handleToggle = (id) => {
		if(toggleQuestion === id){
			setToggleQuestion();
		}else{
			setToggleQuestion(id);
		}
	}
	return(
		<div className="App">
		{/* Render Header */}
	      <HeaderComponent activePage={"07"} />
		{/* Render Body */}
		<div className={"faq"} id="faq">
            <div className="container-investor">
				<div className="title-frequently">Frequently Asked Questions</div>

				<div className="title-welcome">
					<span>Welcome to TimeStope’s official FAQ!</span>
					<img alt="TimeStope’s Team" src={require('../../assets/images/1.png')} />
				</div>

				<div className="list-questions">
					
					{listFaq.map((item,ind) => {
						return(
							<div className="item-question" key={ind}>
								<div className="title-question">{item.title}</div>
									
									<div className="description-question">{item.description}
										{item.answer === '' ? null :<span className='read-more' onClick={()=> handleToggle(item.id)}> {toggleQuestion === item.id ? 'Read less' : 'Read more'}</span>}
									</div>
									{toggleQuestion === item.id && <div className="answer">{item.answer}</div>}
							</div>
						)
					})}
				</div>
				
			</div>
		</div>
		{/* Render Footer */}
	      <FooterComponent />
    	</div>
	)
}
export default FAQComponent;